import * as React from "react"
import { Field, FieldProps } from "react-final-form"
import { Typography } from "@mui/material"
import classNames from "classnames"
import FormError from "../FormError"
import StyledSelect from "../StyledSelect/StyledSelect"

export interface SelectInputProps extends FieldProps<any, any, HTMLElement> {
  name: string
  options: Object[]
  keyCol: string
  valueCol: string
  title?: string
  optional?: boolean
  disabled?: boolean
  displayId?: boolean
  nullOption?: boolean
  nullOptionDisplayText?: string
  width?: number | string
  hideMinWidth?: boolean
  onBlur?: () => void
}

export function FormSelectInput({
  name,
  options,
  keyCol,
  valueCol,
  title,
  width,
  onBlur,
  hideMinWidth,
  optional = false,
  disabled = false,
  displayId = true,
  nullOption = true,
  nullOptionDisplayText,
  fieldProps = {},
  classes,
  ...rest
}: SelectInputProps) {
  const style = {
    width: width || "100%",
    padding: 0,
    minWidth: hideMinWidth ? 0 : "220px"
  }
  return (
    <Field
      name={name}
      subscription={{ value: true, touched: true, error: true, initial: true }}
      {...fieldProps}
      {...rest}
      render={({ input, meta }) => (
        <div>
          {title && (
            <Typography
              className={classNames({
                importantFormLabel: !(optional || disabled)
              })}>
              <label htmlFor={input.name}>{title}</label>
            </Typography>
          )}
          <StyledSelect
            style={style}
            {...input}
            disabled={disabled}
            error={meta.error && meta.touched}
            /* className={classNames({
              formInput: true,
              formInputError: meta.error && meta.touched
            })} */
            onBlur={onBlur}
            nullOption={nullOption}
            nullOptionDisplayText={nullOptionDisplayText}
            keyCol={keyCol}
            valueCol={valueCol}
            displayId={displayId}
            options={options}
          />
          <FormError name={name} />
        </div>
      )}
    />
  )
}
export default FormSelectInput
