import { useQuery } from "react-query"
import { fetchJournaler } from "../../services/api"
import { Journal } from "./types"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

const getJournaler = async id => {
  const { data } = await fetchJournaler(id)
  return data
}

export default function useJournaler(sakId?: number) {
  const kundeId = useSelectedKundeId()
  const query = useQuery<Journal[]>(
    ["journaler", { kundeId, sakId }],
    () => getJournaler(sakId),
    { enabled: !!sakId, retry: 0 }
  )

  return { ...query, data: query.data || [] }
}
