import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography
} from "@mui/material"
import * as React from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Waypoint } from "react-waypoint"
import Chat from "../../../components/Chat/Chat"
import GenericTable from "../../../components/GenericTable"
import LoadingSpinner from "../../../components/LoadingSpinner"
import TabbableTableLink from "../../../components/TabbableTableLink/TabbableTableLink"
import config from "../../../config"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import { ArealplanId } from "../../../hooks/arealplaner/types"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import useMeta from "../../../hooks/meta/useMeta"
import useSearch from "../../../hooks/search/useSearch"
import { useNavigateToArealplan } from "../../../hooks/useNavigateToArealplan"
import { addSortFilter } from "../../../store/search/actions"
import { Order, OrderBy } from "../../../store/search/types"
import useCan from "../../../utils/auth/useCan"
import { formatDate } from "../../../utils/formatDate"
import { setTitle } from "../../../utils/setTitle"
import IkraftDatoDisplay from "../../plan/IkraftDatoDisplay"
import PlanNotifications from "../../plan/PlanNotifications"
import PlanStatusChip from "../../plan/PlanStatusChip"
import SearchResultMobile from "../SearchResultMobile"
import SortTableBtn from "./SortButton"

const cellPadding = "4px 8px 4px 4px"

function SearchResult() {
  const params = useParams()
  const dispatch = useDispatch()
  const search = useSearch()
  const navigateArealplan = useNavigateToArealplan()
  const kundeId = useSelectedKundeId()
  const can = useCan()
  const has = useMeta()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [downloadLink, setDownloadLink] = useState<URL | null>(null)
  const [chatOpen, setChatOpen] = useState(false)

  const handleSortSearchResult = (order: Order, orderBy: OrderBy) => {
    dispatch(addSortFilter({ order, orderBy }))
  }

  const columns = [
    {
      title: "",
      cellStyle: {
        padding: cellPadding
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties,
      render: plan => (
        <PlanNotifications
          plan={plan}
          behandlinger={plan.Behandlinger}
          variant="icon"
        />
      )
    },
    {
      title: (
        <SortTableBtn
          title="Planid"
          value={"PlanId" as OrderBy}
          onClick={(order, orderBy) => {
            handleSortSearchResult(order, orderBy)
          }}
        />
      ),
      field: "planId",
      cellStyle: {
        padding: cellPadding
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties
    },
    {
      title: (
        <SortTableBtn
          title="Navn"
          value={"PlanNavn" as OrderBy}
          onClick={(order, orderBy) => handleSortSearchResult(order, orderBy)}
        />
      ),
      field: "planNavn",
      cellStyle: {
        padding: cellPadding
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties,
      render: (searchResult: { planNavn: string; id: ArealplanId }) => (
        <TabbableTableLink
          text={searchResult.planNavn}
          url={`/${params["customerId"]}/arealplaner/${searchResult.id}`}
          addSearchParams={true}
        />
      )
    },
    {
      title: (
        <SortTableBtn
          title="Type"
          value={"PlanType" as OrderBy}
          onClick={(order, orderBy) => handleSortSearchResult(order, orderBy)}
        />
      ),
      field: "planType",
      cellStyle: {
        padding: cellPadding
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties
    },
    {
      title: (
        <SortTableBtn
          title="Status"
          value={"PlanStatus" as OrderBy}
          onClick={(order, orderBy) => handleSortSearchResult(order, orderBy)}
        />
      ),
      field: "planStatus",
      cellStyle: {
        padding: cellPadding
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties,
      render: plan => (
        <>
          <PlanStatusChip
            planStatusId={plan.planStatusId}
            planStatus={plan.planStatus}
          />
        </>
      )
    },
    {
      title: (
        <SortTableBtn
          title="Ikrafttredelsesdato"
          value={"ikrafttredelsesdato" as OrderBy}
          onClick={(order, orderBy) => handleSortSearchResult(order, orderBy)}
        />
      ),
      field: "komnr",
      cellStyle: {
        padding: cellPadding,
        whiteSpace: "nowrap"
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties,
      render: plan => <IkraftDatoDisplay plan={plan} />
    },
    {
      title: (
        <SortTableBtn
          title="Sist behandlet"
          value={"sistbehandlet" as OrderBy}
          onClick={(order, orderBy) => handleSortSearchResult(order, orderBy)}
        />
      ),
      field: "sistBehandlet",
      cellStyle: {
        padding: cellPadding,
        whiteSpace: "nowrap"
      } as React.CSSProperties,
      headerStyle: {
        padding: cellPadding,
        backgroundColor: "white"
      } as React.CSSProperties,
      render: plan => <Typography>{formatDate(plan.sistBehandlet)}</Typography>
    }
  ]

  React.useEffect(() => {
    setTitle("Søk")
    const link = new URL(
      config.planregister.url +
        "/api/kunder/" +
        params["customerId"] +
        "/arealplaner/csv"
    )
    link.search = location.search
    link.searchParams.append("apitoken", config.planregister.token)
    setDownloadLink(link)
  }, [location.search, config.planregister.token, config.planregister.url])

  const handleRowClick = (arealplanId: ArealplanId) => {
    navigateArealplan.toArealplan(arealplanId)
  }

  const handleLoadMore = () => {
    search.fetchNextPage()
  }

  return (
    <Box
      sx={theme => ({
        [theme.breakpoints.up("md")]: { margin: "0 auto", maxWidth: "90%" },
        [theme.breakpoints.up("lg")]: { maxWidth: "70%" }
      })}>
      <Grid
        container={true}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        style={{ height: "60px" }}>
        {search.isFetching ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={15} />
            <p style={{ marginLeft: 8 }}>Laster inn treff</p>
          </div>
        ) : (
          <>
            <Grid item={true}>{search.totalCount || 0} planer funnet</Grid>
            {downloadLink && (
              <Grid item={true}>
                <Button onClick={event => setAnchorEl(event.currentTarget)}>
                  ...
                </Button>
                <Menu
                  id="search-results-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  disableScrollLock={true}>
                  <MenuItem aria-label="Last ned som .csv">
                    <a
                      href={downloadLink.href}
                      onClick={() => setAnchorEl(null)}>
                      Last ned som .csv
                    </a>
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <MobileContainer>
        <SearchResultMobile />
      </MobileContainer>
      <DesktopContainer>
        {search.isFetched && !search.results.length ? (
          <Grid item={true} xs={12}>
            Aktive filtre ga ingen resultat. Endre filter for å se aktuelle
            planer.
          </Grid>
        ) : (
          <div style={{ marginBottom: "60px" }}>
            <div className="tabbable-table-wrapper">
              <GenericTable
                title="Søkeresultater"
                columns={columns}
                idColumn="id"
                data={search.results}
                onRowClick={(_, plan) => {
                  handleRowClick(plan.id)
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: !search.isFetched
                      ? ""
                      : `
                    Aktive filtre ga ingen resultat. Endre filter for å se aktuelle
                    planer`
                  }
                }}
              />
            </div>
            {has.hasPlanprat() && can.edit ? (
              <Button
                sx={{ mt: 5, mb: 5 }}
                variant="outlined"
                onClick={() => setChatOpen(!chatOpen)}>
                {chatOpen ? "Lukk planprat" : "Spør planroboten"}
              </Button>
            ) : (
              <></>
            )}
            {chatOpen ? (
              <Box
                sx={{
                  minHeight: "300px",
                  maxHeight: "1000px",
                  overflowY: "auto",
                  backgroundColor: "#F7F6FF"
                }}>
                <Chat
                  plans={search.results}
                  kundeId="906ee49b-e10b-4ab4-ae76-dd16046f8e27"
                />
              </Box>
            ) : (
              <></>
            )}
          </div>
        )}
      </DesktopContainer>
      {search.isFetchingNextPage && (
        <div style={{ marginTop: "-60px" }}>
          <LoadingSpinner />
        </div>
      )}
      <Waypoint onEnter={handleLoadMore} bottomOffset="-400px" />
    </Box>
  )
}

export default SearchResult
