import * as React from "react"
import { hideDialog } from "../../../store/dialog/actions"
import { useDispatch } from "react-redux"
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { DokumentUtvidet } from "../../plan/PlanDocumentList/PlanDocumentListDocuments"
import RearrangeDocumentsTable from "../RearrangeDocumentsTable"
import {
  updateArealplanDokumentrekkefolge,
  updateBehandlingDokumentrekkefolge,
  updateDispensasjonDokumentrekkefolge
} from "../../../services/api"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { Behandling } from "../../../hooks/behandlinger/types"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import { useQueryClient } from "react-query"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"

export type OwnProps = {
  behandling?: Behandling
  dispensasjon?: Dispensasjon
  documents: DokumentUtvidet[]
}

type Props = OwnProps & {
  open: boolean
}

function DocumentFormDialog({ open, ...props }: Props) {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { data: plan, refetch } = useArealplan()
  const [documents, setDocuments] = React.useState<DokumentUtvidet[]>(
    props.documents || []
  )
  const kundeId = useSelectedKundeId()

  React.useEffect(() => {
    setDocuments(props.documents)
  }, [props.documents])

  const [isUploading, setIsUploading] = React.useState(false)

  function handleClose() {
    hideDialog(dispatch).then(() => {
      setIsUploading(false)
    })
  }

  const handleSubmit = async () => {
    setIsUploading(true)
    const updatedDocs = documents.map((doc, i) => ({
      ...doc,
      rekkefolge: i,
      rekkefolgeBehandling: i,
      rekkefolgeDispensasjon: i
    }))

    if (props.behandling) {
      try {
        const res = await updateBehandlingDokumentrekkefolge(
          props.behandling.id,
          updatedDocs
        )
        queryClient.invalidateQueries([
          "behandlinger",
          { arealplanId: plan.id }
        ])
      } catch {
        // TODO: Silent error?
      }
    }

    if (props.dispensasjon) {
      try {
        const res = await updateDispensasjonDokumentrekkefolge(
          props.dispensasjon.id,
          updatedDocs
        )
        queryClient.invalidateQueries([
          "dispensasjoner",
          { arealplanId: plan.id }
        ])
      } catch {
        // TODO: Silent error?
      }
    }

    // Assume arealplan
    if (!props.behandling && !props.dispensasjon) {
      try {
        const res = await updateArealplanDokumentrekkefolge(
          plan.id,
          updatedDocs
        )
        queryClient.invalidateQueries([
          "dokumenter",
          { kundeId, arealplanId: plan.id }
        ])
      } catch {
        // TODO: Silent error?
      }
    }

    handleClose()
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
      PaperProps={{ style: { overflowY: "visible" } }}
      disableScrollLock={true}>
      <DialogTitle id="form-dialog-title">
        <Grid
          container={true}
          justifyContent="space-between"
          spacing={8}
          alignItems="center">
          <Grid item={true}>Endre dokumentrekkefølge</Grid>
          <Grid item={true}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowY: "auto" }}>
        {isUploading ? (
          <LoadingSpinner text="Lagrer..." />
        ) : (
          <RearrangeDocumentsTable
            documents={documents}
            setDocuments={setDocuments}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!isUploading ? (
          <>
            <Button onClick={handleClose} variant="contained" color="grey">
              Avbryt
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary">
              Lagre
            </Button>
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

export default DocumentFormDialog
