import { Box, Tab, Tabs } from "@mui/material"
import React from "react"
import useMeta from "../../../hooks/meta/useMeta"
import { TokenType } from "../../../hooks/tokens/types"
import TokensTab from "./TokensTab"

function Tokens() {
  const meta = useMeta()
  const [selectedTab, setSelectedTab] = React.useState("wms")

  const handleChange = (event: React.ChangeEvent<{}>, tabClicked: string) => {
    setSelectedTab(tabClicked)
  }

  const renderWmsTab = () => (
    <TokensTab
      tokenType={TokenType.WMS}
      description={"Tilganger til WMS med dispensasjoner og mindre endringer"}
    />
  )

  const renderApiTab = () => (
    <TokensTab
      tokenType={TokenType.API}
      description={
        <>
          Tilganger til{" "}
          <a
            href="https://api.arealplaner.no/swagger/index.html"
            target="blank">
            api.arealplaner.no
          </a>
        </>
      }
    />
  )

  const renderTabs = () => (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary">
        <Tab label="WMS" value="wms" />
        <Tab label="API" value="api" />
      </Tabs>
      <Box padding={10}>
        {selectedTab == "wms" && renderWmsTab()}
        {selectedTab == "api" && renderApiTab()}
      </Box>
    </>
  )

  return meta.hasApiTilgang() ? renderTabs() : renderWmsTab()
}

export default Tokens
