export interface SessionState {
  activePlanTab?: string
  activeSakId?: number
  useAltFrontpage: boolean
  expandDispensasjonId?: string
  expandBehandlingId?: string
}

export interface SetActivePlanTabAction {
  type: typeof SessionActionTypes.SET_ACTIVE_PLAN_TAB;
  payload: string | undefined;
}

export interface SetActiveSakIdAction {
  type: typeof SessionActionTypes.SET_ACTIVE_SAK_ID;
  payload: number | undefined;
}

export interface UseAlternativeFrontpageAction {
  type: typeof SessionActionTypes.USE_ALTERNATIVE_FRONTPAGE;
  payload: boolean;
}

export interface SetExpandDispensasjonIdAction {
  type: typeof SessionActionTypes.SET_EXPAND_DISPENSASJON_ID;
  payload: string | undefined;
}

export interface SetExpandBehandlingIdAction {
  type: typeof SessionActionTypes.SET_EXPAND_BEHANDLING_ID;
  payload: string | undefined;
}

export enum SessionActionTypes {
  SET_ACTIVE_PLAN_TAB = "@@planregister/session/SET_ACTIVE_PLAN_TAB",
  SET_ACTIVE_SAK_ID = "@@planregister/session/SET_ACTIVE_SAK_ID",
  USE_ALTERNATIVE_FRONTPAGE = "@@planregister/session/USE_ALTERNATIVE_FRONTPAGE",
  SET_EXPAND_DISPENSASJON_ID = "@@planregister/session/SET_EXPAND_DISPENSASJON_ID",
  SET_EXPAND_BEHANDLING_ID = "@@planregister/session/SET_EXPAND_BEHANDLING_ID"
}
