import * as React from "react"

import CheckIcon from "@mui/icons-material/CheckCircle"
import ProcessIcon from "@mui/icons-material/SwapHorizontalCircle"
import RejectIcon from "@mui/icons-material/Cancel"
import UnknownIcon from "@mui/icons-material/Help"

import { Chip, ChipProps, IconProps, Theme } from "@mui/material"
import { green, amber } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

export interface PlanStatusChipProps {
  planStatusId: number
  planStatus: string
}

const statusIcons = {
  accepted: CheckIcon,
  process: ProcessIcon,
  rejected: RejectIcon,
  unknown: UnknownIcon
}

type StyledChipProps = ChipProps & {
  iconStyle: string
}

const options = {
  shouldForwardProp: prop => prop !== "iconStyle"
}

const StyledChip = styled(
  Chip,
  options
)<StyledChipProps>(({ iconStyle, theme }) => ({
  backgroundColor: "transparent",
  justifyContent: "left",
  marginLeft: 0,
  ...(iconStyle === "accepted" && {
    "& .MuiChip-icon": { color: green[600] }
  }),
  ...(iconStyle === "process" && {
    "& .MuiChip-icon": { color: amber[700] }
  }),
  ...(iconStyle === "rejected" && {
    "& .MuiChip-icon": { color: theme.palette.error.dark }
  })
}))

function PlanStatusChip({ planStatusId, planStatus }: PlanStatusChipProps) {
  let iconStyle = "unknown"
  if ([3].includes(planStatusId)) iconStyle = "accepted"
  if ([0, 1, 2, 6].includes(planStatusId)) iconStyle = "process"
  if ([4, 5, 8, 9, 10].includes(planStatusId)) iconStyle = "rejected"

  const Icon = statusIcons[iconStyle]
  return (
    <>
      <StyledChip icon={<Icon />} label={planStatus} iconStyle={iconStyle} />
    </>
  )
}

export default PlanStatusChip
