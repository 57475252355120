import * as React from "react"
import { useQuery } from "react-query"
import { graphQLRequest } from "../../services/api"
import { planstatusPlantypeQuery } from "./customReportQueries"
import { PlanstatusPlantypeReport } from "./types"

export function usePlanstatusPlantypeGraphQLCsv() {
  let headers = [
    "PlantypeId",
    "Plantype",
    "Gjeldende",
    "Planer under arbeid",
    "Ubestemt",
    "Utgått"
  ]

  const reactQuery = useQuery<any>(
    ["planstatusPlantypeReportGraphQLCsv"],
    () => graphQLRequest(planstatusPlantypeQuery),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
      keepPreviousData: false
    }
  )

  const csvArray = React.useMemo(() => {
    if (reactQuery.data) {
      const planstatusPlantypeReport: PlanstatusPlantypeReport[] =
        reactQuery.data.planstatusPlantypeReport

      const newLines = planstatusPlantypeReport.reduce((acc: any, d) => {
        acc.push(
          [
            d.plantype.id,
            d.plantype.beskrivelse,
            d.gjeldende,
            d.underArbeid,
            d.ubestemt,
            d.utgatt
          ].join(";")
        )
        return acc
      }, [])

      return newLines
    }
  }, [reactQuery.data])

  return { ...reactQuery, csvData: csvArray, headers: headers }
}
