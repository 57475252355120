import React, { useState, useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import Footer from "../footer"
import "../faq/faq.css"
import { ArrowBack } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import SpinnerButton from "../../components/SpinnerButton"
import { openSnackbarMessage } from "../../components/SnackbarMessage"
import sanitizeHtml from "sanitize-html"
import useCan from "../../utils/auth/useCan"
import useMeta from "../../hooks/meta/useMeta"
import useUpdateInnstillinger from "../../hooks/meta/useUpdateInnstillinger"
import { useNavigate } from "react-router-dom"
import { ToiBox } from '@norkart/toi-components'
import Editor from "../../components/TextEditor/Editor"

const RelevantLinks = () => {
  const can = useCan()
  const meta = useMeta()
  const {
    mutateAsync: updateInnstillinger,
    isLoading
  } = useUpdateInnstillinger()
  
  const navigate = useNavigate()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [initialHTML, setInitialHTML] = useState<string>('')
  const [editorHTML, setEditorHTML] = useState<string>('');

  const handleEditorState = (newEditorHTML: string) => {
    setEditorHTML(newEditorHTML);
  };

  useEffect(() => {
    if (meta.relevanteLinker) {
      setInitialHTML(meta.relevanteLinker);
    }
  }, [meta.relevanteLinker])

  const onCancel = () => {
    setIsEditMode(false)
  }

  const save = () => {
    updateInnstillinger({
      Innstilling: "relevante_linker",
      Verdi: editorHTML,
    }).then((response: any) => {
      if (response.response && response.response.status !== 200) {
        openSnackbarMessage({
          message: "Noe gikk galt, prøv igjen senere",
          variant: "error"
        })
      } else {
        setIsEditMode(false)
        setInitialHTML(editorHTML)
      }
    })
  }

  return (
    <>
      <ToiBox
        sx={{
          marginTop: "20px",
          marginLeft: "20px"
        }}>
        <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
          Tilbake
        </Button>
        {!isEditMode && can.edit && (
          <Button
            variant="text"
            size="small"
            onClick={() => setIsEditMode(true)}
            sx={theme => ({
              marginRight: "4px",
              [theme.breakpoints.down("sm")]: { width: "80%" }
            })}>
            <EditIcon fontSize="small" sx={{ marginRight: "4px" }} />
            <Typography
              sx={{ fontSize: "1rem", margin: "5px", fontWeight: 400 }}>
              Endre
            </Typography>
          </Button>
        )}
      </ToiBox>
      <ToiBox
        sx={{
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
          maxWidth: "1000px",
        }}>
        {isEditMode ? (
          <>
            <Editor
            initialEditorContent={meta.relevanteLinker ? meta.relevanteLinker : ''}
            toolbarOptions={['bold', 'italic', 'list', 'undo', 'redo', 'link']}
            currentEditorState={handleEditorState}
          />
            <Button
              variant="contained"
              color="grey"
              onClick={onCancel}
              disabled={isLoading}>
              Avbryt
            </Button>
            <SpinnerButton
              type="submit"
              label="Lagre"
              style={{ marginLeft: "20px" }}
              onClick={() => save()}
              disabled={editorHTML === meta.relevanteLinker}
              loading={isLoading}
            />
          </>
        ) : (
          <ToiBox
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(initialHTML) }}
          />
        )}
      </ToiBox>
      <Footer />
    </>
  )
}
export default RelevantLinks
