import * as React from "react"
import { connect } from "react-redux"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { DialogContent } from "@mui/material"
import { ApplicationState } from "../../../store/index"
import {
  emptyDocumentSelection,
  SelectedDocument
} from "../../../store/selectedDocumentsTable/reducer"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import { removeDocument } from "../../../store/selectedDocumentsTable/reducer"
import { Arealplan } from "../../../hooks/arealplaner/types"
import {
  documentIsArvet,
  documentHasBehandling
} from "../../../utils/documentIsArvet"
import useArealplanDokumenter from "../../../hooks/dokumenter/useArealplanDokumenter"
import { deleteDokument } from "../../../services/api"
import { useQueryClient } from "react-query"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"

export interface DeleteDocumentsDialogProps {
  open: boolean
  onClose: () => void
  selectedDocuments: SelectedDocument[]
  emptyDocumentSelection: () => void
  removeDocumentFromSelected: (docId: number) => void
  plan: Arealplan
}

function DeleteDocumentsDialog({
  open,
  onClose,
  emptyDocumentSelection,
  plan,
  selectedDocuments,
  removeDocumentFromSelected
}: DeleteDocumentsDialogProps) {
  const { data: dokumenter } = useArealplanDokumenter()
  const [deleteDocumentsPending, setDeleteDocumentsPending] = React.useState(
    false
  )
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()

  const deleteSelectedDocuments = () => {
    setDeleteDocumentsPending(true)
    asyncForEach(documentsToBeDeleted, deleteDocument, () => {
      setDeleteDocumentsPending(false)
      onClose()
      queryClient.invalidateQueries([
        "dokumenter",
        { kundeId, arealplanId: plan.id }
      ])
      queryClient.invalidateQueries([
        "behandlinger",
        { kundeId, arealplanId: plan.id }
      ])
      // Hvis dokument er relatert
      queryClient.invalidateQueries([
        "arealplan",
        { kundeId, arealplanId: plan.id }
      ])
    })
  }

  const deleteDocument = async (doc: SelectedDocument) => {
    try {
      const res = await deleteDokument(doc.dokumentId)
      removeDocumentFromSelected(doc.dokumentId)
      return true
    } catch (err) {
      openSnackbarMessage({
        variant: "error",
        message: "Kunne ikke slette ett eller flere dokument"
      })
      onClose()
      setDeleteDocumentsPending(false)
      return false
    }
  }

  async function asyncForEach(array: any[], callback, onSuccess) {
    let successfulRequest
    for (let i = 0; i < array.length; i++) {
      successfulRequest = await callback(array[i])
      if (!successfulRequest) {
        break
      }
    }
    if (successfulRequest) {
      onSuccess()
    }
  }

  const documentsToBeDeleted = selectedDocuments.filter(
    doc =>
      !documentIsArvet(doc.dokumentId, plan, dokumenter) &&
      !documentHasBehandling(doc.dokumentId, plan, dokumenter)
  )
  const arvetDocuments = selectedDocuments.filter(doc =>
    documentIsArvet(doc.dokumentId, plan, dokumenter)
  )
  const documentsWithBehandling = selectedDocuments.filter(doc =>
    documentHasBehandling(doc.dokumentId, plan, dokumenter)
  )
  const documentsNotToDelete = arvetDocuments.concat(documentsWithBehandling)

  return (
    <Dialog open={open} fullWidth={true}>
      {deleteDocumentsPending && (
        <DialogContent>
          <LoadingSpinner
            text={`${
              documentsToBeDeleted.length === 1
                ? "Sletter dokument"
                : "Sletter valgte dokumenter"
            }`}
          />
        </DialogContent>
      )}
      {!deleteDocumentsPending && (
        <DialogContent>
          <p>
            {documentsNotToDelete.length && !documentsToBeDeleted.length ? (
              <p>{`${
                documentsNotToDelete.length === 1 ? "Dokumentet" : "Dokumentene"
              } kan ikke slettes fordi ${
                documentsNotToDelete.length === 1
                  ? "det er arvet fra en annen plan, eller allerede er knyttet til en behandling"
                  : "de er arvet fra en annen plan, eller allerede er knyttet til en behandling"
              }.`}</p>
            ) : (
              ""
            )}
            {documentsNotToDelete.length && documentsToBeDeleted.length ? (
              <div>
                <p>{`${documentsNotToDelete.length} ${
                  documentsNotToDelete.length === 1
                    ? "dokument kan ikke slettes fordi det er arvet fra en annen plan, eller allerede er knyttet til en behandling"
                    : "dokumenter kan ikke slettes fordi de er arvet fra en annen plan, eller allerede er knyttet til en behandling"
                }: `}</p>
                <ul>
                  {documentsNotToDelete.map(doc => (
                    <li key={doc.dokumentId}>{doc.filename}</li>
                  ))}
                </ul>
                <p>{`${documentsToBeDeleted.length} kan slettes:`}</p>
                <ul>
                  {documentsToBeDeleted.map(doc => (
                    <li key={doc.dokumentId}>{doc.filename}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}

            {documentsToBeDeleted.length
              ? `${
                  documentsToBeDeleted.length > 1 ? "Dokumentene" : "Dokumentet"
                } vil slettes helt fra planregisteret, og du kan ikke angre. Er du sikker på at du vil slette ${
                  documentsToBeDeleted.length > 0 && documentsToBeDeleted.length
                } ${
                  documentsToBeDeleted.length > 1 ? "dokumenter" : "dokument"
                }?`
              : ""}
          </p>
          <DialogActions>
            {documentsToBeDeleted.length > 0 ? (
              <>
                <Button
                  onClick={() => onClose()}
                  variant="contained"
                  color="grey">
                  Nei
                </Button>
                <Button
                  onClick={deleteSelectedDocuments}
                  variant="contained"
                  color="secondary">
                  Ja
                </Button>
              </>
            ) : (
              <Button
                onClick={() => onClose()}
                variant="contained"
                color="grey">
                Avbryt
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default connect(
  (state: ApplicationState, ownProps: any) => ({
    selectedDocuments: state.tableSelection.selectedDocuments
  }),
  dispatch => ({
    emptyDocumentSelection: () => {
      dispatch(emptyDocumentSelection())
    },
    removeDocumentFromSelected: (docId: number) => {
      dispatch(removeDocument(docId))
    }
  })
)(DeleteDocumentsDialog)
