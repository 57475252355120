import React, { createRef, useEffect, useState } from "react"
import WarningIcon from "@mui/icons-material/Warning"
import useApplikasjonInnstillinger from "../../hooks/applikasjonInnstillinger/useApplikasjonInnstillinger"
import _ from "lodash-es"
import { Box, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useAuth } from "../../utils/auth"

export default function StatusMelding() {
  const { data } = useApplikasjonInnstillinger()
  const { isLoggedIn } = useAuth()
  const [showStatusmessage, setShowStatusmessage] = useState(true)

  const [smHeight, setSmHeight] = useState<number>(0)
  const smBanner = createRef<HTMLDivElement>()

  const statusmelding = {
    isActive: _.find(data, function (o) {
      return o.id === "statusmelding_isActive"
    })?.verdi,
    msg: _.find(data, function (o) {
      return o.id === "statusmelding_msg"
    })?.verdi,
    intern: _.find(data, function (o) {
      return o.id === "statusmelding_intern"
    })?.verdi
  }

  useEffect(() => {
    if (smBanner.current) {
      setSmHeight(smBanner.current.clientHeight)
    }
  }, [smBanner])

  const displayStatusmelding = () => {
    if (!data || statusmelding.isActive != "1") return false
    if (statusmelding.intern == "1" && !isLoggedIn) return false
    return true
  }

  if (statusmelding.isActive === "1") {
    window.addEventListener(
      "resize",
      function () {
        if (smBanner.current) {
          setSmHeight(smBanner.current.clientHeight)
        }
      },
      false
    )
  }

  const hideStatusmessage = () => {
    setShowStatusmessage(false)
  }

  if (!displayStatusmelding()) return null

  return showStatusmessage ? (
    <div style={{ height: `${smHeight}px` }}>
      <Box
        ref={smBanner}
        sx={theme => ({
          zIndex: theme.zIndex.drawer,
          width: "100%",
          minHeight: "40px",
          position: "fixed",
          top: theme.mixins.toolbar.height,
          display: "flex",
          alignItems: "center",
          columnGap: "16px",
          padding: "0px 16px",
          background: "#FFF6E7"
        })}>
        <WarningIcon
          sx={{
            color: "#ffa000",
            transform: "scale(.8)"
          }}
        />
        <Typography
          style={{
            width: "100%",
            fontWeight: 500,
            lineHeight: "20px",
            padding: "10px 0px"
          }}
          component="span">
          {statusmelding.msg}
        </Typography>
        <IconButton onClick={() => hideStatusmessage()}>
          <CloseIcon />
        </IconButton>
      </Box>
    </div>
  ) : null
}
