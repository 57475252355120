import {
  getNorkartIdAuth,
  getNorkartIdAuthData,
  getAuthorization as getAuthorizationNkMsal,
  useLoginPopup,
  useLogout,
  INkMsalContext,
  useNkMsal,
  useIsLoggedIn
} from "@norkart/nk-msal"
import config from "../../config"
import { useDispatch, useSelector } from "react-redux"
import { RedirectRequest, SilentRequest } from "@azure/msal-browser"
import { getAuthorization } from "../../store"
import {
  resetAuthState,
  setAuthIsLoading,
  setAuthorization
} from "../../store/auth/actions"

const clientId = config.planregister.client_id
const authority = config.planregister.authority
const setupNorkartProfile = config.planregister.profile
const apiGeneralAccessScope = config.planregister.api_scope
export const norkartIdAuthData = getNorkartIdAuthData(
  clientId,
  authority,
  [apiGeneralAccessScope],
  setupNorkartProfile
)

export const norkartIdAuth = getNorkartIdAuth(
  norkartIdAuthData.msalApp,
  norkartIdAuthData
)

const loginRequest: RedirectRequest = {
  scopes: norkartIdAuthData.msalApiScopes
}

const silentLoginRequest: SilentRequest = {
  scopes: norkartIdAuthData.msalApiScopes
}

export function useAuth() {
  const dispatch = useDispatch()
  const loginNkmsal = useLoginPopup(loginRequest)
  const logoutNkmsal = useLogout()
  const userData: INkMsalContext = useNkMsal()
  const isLoggedIn = useIsLoggedIn()

  const authorization = useSelector(getAuthorization)

  const getAuth = async () => {
    dispatch(setAuthIsLoading(true))
    const authResponse = await getAuthorizationNkMsal(norkartIdAuthData)
    dispatch(setAuthorization(authResponse))
    dispatch(setAuthIsLoading(false))
  }

  const login = async () => {
    await loginNkmsal()
    getAuth()
  }

  const logout = async () => {
    await logoutNkmsal()
    dispatch(resetAuthState())
  }

  const silentLogin = async () => {
    try {
      await norkartIdAuthData.msalApp.ssoSilent(silentLoginRequest)
      const account = norkartIdAuthData.msalApp.getAllAccounts()[0]
      norkartIdAuthData.msalApp.setActiveAccount(account)
      await getAuth()
    } catch {
      dispatch(resetAuthState())
    }
  }

  const getUserName = (): string => {
    const userAccount = userData.accounts[0]
    return userAccount ? userAccount.name ?? userAccount.username : "Bruker"
  }

  return {
    authorization,
    getAuth,
    silentLogin,
    login,
    logout,
    getUserName,
    isLoggedIn
  }
}
