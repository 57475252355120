import { useMemo } from "react"
import { useQuery } from "react-query"
import { fetchKonsistenssjekk } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { KonsistenssjekkProps, LogLines } from "./types"

const getKonsistenssjekk = async () => {
    const { data } = await fetchKonsistenssjekk()
    return data
  }

  export default function useKonsistenssjekk() {
    const { notify } = useErrorHandler()
    const kundeId = useSelectedKundeId()
  
    const query = useQuery<KonsistenssjekkProps>(
      ["konsistenssjekk", { kundeId }],
      () => getKonsistenssjekk(),
      {
        onError: notify,
        enabled: !!kundeId,
        refetchInterval: 30000
      }
    )
  const mappedLoglines: LogLines[] | undefined = useMemo(() => {
    if(query?.data?.report?.logLines) {
      const logLines =  query?.data?.report?.logLines.map((l, i) => {
        const logLineWithId: Partial<LogLines> = {id:i, planId: l.planId, kategoriFeil:l.kategoriFeil, qmsOppgavenavn: l.qmsOppgavenavn, verdiArealplaner:l.verdiArealplaner, verdiQms: l.verdiQms}
        return logLineWithId
      })

      return logLines
    }
  },[query?.data?.report?.logLines])

  const mappedTempLoglines: LogLines[] | undefined = useMemo(() => {
    if(query?.data?.tempReport?.logLines) {
      const tempLogLines =  query?.data?.tempReport?.logLines.map((l, i) => {
        const logLineWithId = {...l, id:i}
        return logLineWithId
      })

      return tempLogLines
    }
    }
  ,[query?.data?.tempReport?.logLines])

  const reportFailedMessage = useMemo(() => {
    if(query.data?.tempReport && !query.data?.tempStartDato) {
      if(query.data.tempReport.logLines) {
        if(query.data.tempReport.logLines.length === 1)
          return JSON.stringify(query.data?.tempReport?.logLines[0], null, 2)
        else return JSON.stringify(query.data?.tempReport?.logLines.pop(), null, 2)
      }
      
    } else return
  }, [query.data?.tempStartDato])

    return {
      ...query,
      data:  query.data || {} as KonsistenssjekkProps,
      loglines: mappedLoglines,
      tempLoglines: mappedTempLoglines,
      isRunning: query.data?.tempStartDato ? true : false,
      reportFailed: reportFailedMessage
    }
  }
  