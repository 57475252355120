import _ from "lodash-es"
import useKunder from "../../hooks/kunder/useKunder"
import useSelectedKundeId from "../../hooks/kunder/useSelectedKundeId"
import { useAuth } from "./auth"

export default function useCan() {
  const selectedKundeId = useSelectedKundeId()
  const { data: kunder } = useKunder()

  const { authorization } = useAuth()

  //   // Debug
  //   useEffect(() => {
  //     console.log("manage:", manage())
  //     console.log("admin:", admin())
  //     console.log("edit:", edit())
  //     console.log("editDisp:", editDisp())
  //     console.log("view:", view())
  //     console.log(auth)
  //   }, [authorization, selectedKundeId])

  const getSelectedCustomerAliases = () => {
    const aliases = [] as string[]
    const kunde = kunder.filter(
      k => k.id == selectedKundeId || k.kommunenummer == selectedKundeId
    )[0]
    if (kunde) {
      aliases.push(kunde?.id)
      aliases.push(kunde?.kommunenummer)
    } else {
      aliases.push(selectedKundeId)
    }
    return aliases
  }

  function checkCustomerParameter(parameter: string): boolean {
    // Alle kunder tillatt
    var setting =
      (authorization && authorization.getStringSetting(parameter)) || ""
    if (setting === "*") return true

    const allowCustomers = setting.split(",")
    const aliases = getSelectedCustomerAliases()
    return _.intersection(allowCustomers, aliases).length > 0
  }

  function manage() {
    return (
      (authorization && authorization.getBooleanSetting("AllowManageAll")) ||
      false
    )
  }

  function admin() {
    return manage() || checkCustomerParameter("AllowAdminCustomers")
  }

  function edit() {
    return admin() || checkCustomerParameter("AllowEditCustomers")
  }

  function editDisp() {
    return edit() || checkCustomerParameter("AllowEditDispCustomers")
  }

  function view() {
    return editDisp() || checkCustomerParameter("AllowViewCustomers")
  }

  const can = () => ({
    admin: admin(),
    edit: edit(),
    editDisp: editDisp(),
    view: view()
  })

  return can()
}
