import React from "react"
import {
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material"
import { Link } from "react-router-dom"
import { Arealplan } from "../../hooks/arealplaner/types"
import useSelectedKundeId from "../../hooks/kunder/useSelectedKundeId"
import useSearch from "../../hooks/search/useSearch"
import { formatDate } from "../../utils/formatDate"
import { formatNasjonalArealplanId } from "../../utils/formatNasjonalArealplanId"
import PlanNotifications from "../plan/PlanNotifications"
import PlanStatusChip from "../plan/PlanStatusChip"
import qs from "qs"
import { UrlSearchParams } from "../../store/search/types"

const StyledTableRow = styled(TableRow)({ height: "30px" })
const StyledTableHeader = styled(TableCell)({
  border: 0,
  fontWeight: 500,
  width: "170px"
})
const StyledTableCell = styled(TableCell)({
  border: 0
})

export default function SearchResultMobile() {
  const customerId = useSelectedKundeId()
  const search = useSearch()
  const urlSearchParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }) as Partial<UrlSearchParams>

  return (
    <div>
      <Grid
        container={true}
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginBottom: "60px" }}
        spacing={8}>
        {search.results.length ? (
          search.results.map((plan: Arealplan) => {
            return (
              <Grid item={true} key={plan.id}>
                <Link
                  to={{
                    pathname: `/${customerId}/arealplaner/${plan.id}`,
                    search: qs.stringify(urlSearchParams)
                  }}
                  style={{ textDecoration: "none" }}>
                  <Paper
                    sx={theme => ({
                      padding: theme.spacing(8),
                      maxWidth: "700px"
                    })}>
                    <Typography
                      variant="h2"
                      sx={theme => ({
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "1.2rem"
                        },
                        fontWeight: 500,
                        marginBottom: "6px"
                      })}>
                      {plan.planNavn}
                    </Typography>
                    <Grid
                      container={true}
                      justifyContent="flex-start"
                      spacing={4}
                      alignItems="center">
                      <Grid item={true}>
                        <PlanNotifications
                          plan={plan}
                          behandlinger={plan.behandlinger}
                          variant="icon"
                        />
                      </Grid>
                      <Grid item={true}>
                        <PlanStatusChip
                          planStatusId={plan.planStatusId}
                          planStatus={plan.planStatus}
                        />
                      </Grid>
                    </Grid>
                    <div style={{ height: "6px" }} />
                    <Table
                      sx={{
                        width: "100%",
                        tableLayout: "fixed",
                        head: {
                          whiteSpace: "nowrap"
                        }
                      }}
                      padding="none">
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableHeader component="th" scope="row">
                            Nasjonal arealplanid:
                          </StyledTableHeader>
                          <StyledTableCell>
                            {formatNasjonalArealplanId(plan)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableHeader component="th" scope="row">
                            Plantype:
                          </StyledTableHeader>
                          <StyledTableCell>{plan.planType}</StyledTableCell>
                        </StyledTableRow>
                        {plan.iKraft && (
                          <StyledTableRow>
                            <StyledTableHeader component="th" scope="row">
                              Ikrafttredelsesdato:
                            </StyledTableHeader>
                            <StyledTableCell>
                              {formatDate(plan.iKraft)}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {plan.sistBehandlet && (
                          <StyledTableRow>
                            <StyledTableHeader component="th" scope="row">
                              Sist behandlet:
                            </StyledTableHeader>
                            <StyledTableCell>
                              {formatDate(plan.sistBehandlet)}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </Link>
              </Grid>
            )
          })
        ) : !search.isFetched ? (
          ""
        ) : (
          <Grid item={true} xs={12}>
            Aktive filtre ga ingen resultat. Endre filter for å se aktuelle
            planer.
          </Grid>
        )}
      </Grid>
    </div>
  )
}
