import * as React from "react"
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContent
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit"
import { ConnectedReduxProps } from "../../../store"
import PlanForm from "../PlanForm"
import { showDialog, hideDialog } from "../../../store/dialog/actions"
import { Arealplan } from "../../../hooks/arealplaner/types"
import useCreateArealplan from "../../../hooks/arealplaner/useCreateArealplan"
import useUpdateArealplan from "../../../hooks/arealplaner/useUpdateArealplan"

export interface PlanFormDialogProps extends ConnectedReduxProps {
  plan: Arealplan
  open: boolean
}

const PlanFormDialog = ({ open, plan, dispatch }: PlanFormDialogProps) => {
  const { mutateAsync: createArealplan } = useCreateArealplan()
  const { mutateAsync: updateArealplan } = useUpdateArealplan()
  const [fullscreen, setFullscreen] = React.useState(false)
  const onClose = () => {
    hideDialog(dispatch)
  }
  const onDelete = () =>
    hideDialog(dispatch).then(() =>
      dispatch(
        showDialog({
          dialogType: "DELETE_PLAN",
          dialogProps: { plan }
        })
      )
    )

  const onSubmit = async plan => {
    return plan.id ? updateArealplan(plan) : createArealplan(plan)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="plan-form-dialog-title"
      fullScreen={fullscreen}
      fullWidth={true}
      maxWidth="md"
      disableScrollLock={true}>
      <DialogTitle id="plan-form-dialog-title">
        <Grid
          container={true}
          justifyContent="space-between"
          spacing={8}
          alignItems="center">
          <Grid item={true}>
            {plan.id ? "Redigere arealplan" : "Registrere ny plan"}
          </Grid>
          <Grid item={true}>
            {fullscreen ? (
              <>
                <IconButton onClick={() => setFullscreen(false)}>
                  <FullscreenExitIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={() => setFullscreen(true)}>
                  <FullscreenIcon fontSize="small" />
                </IconButton>
              </>
            )}
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <PlanForm
          plan={plan}
          onSubmit={onSubmit}
          onCancel={onClose}
          onClose={onClose}
          onDelete={onDelete}
        />
      </DialogContent>
    </Dialog>
  )
}

export default PlanFormDialog
