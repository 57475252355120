export const dispensasjonerReportQuery = `
  query DispensasjonerPaged($first: Int!, $after: String, $filter: DispensasjonReportDtoFilterInput, $sort: [DispensasjonReportDtoSortInput!]) {
    dispensasjonerPaged(first: $first, after: $after, where: $filter, order: $sort) {
      edges {
        node {
            id,
            beskrivelse
            dispensasjonType
            dispensasjonTypeId
            dispFra
            arealplan {
                planNavn
                planId
                planStatus
                planStatusId
                planType
                planTypeId
            }
            sak {
                sakAar,
                sakSeknr
            }
            vedtak
            vedtakId
            vedtaksdato,
            vertikalnivaId
            vertikalnivaType
            posisjon {
                x,
                y,
                z,
                koordsys
            }
        },
        cursor
    },
    pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
    },
    totalCount
    }
  }
  `

export const dispensasjonerReportCsvQuery = `
query DispensasjonerPaged($filter: DispensasjonReportDtoFilterInput, $sort: [DispensasjonReportDtoSortInput!]) {
dispensasjoner(where: $filter, order: $sort) {
        id,
        beskrivelse
        dispensasjonType
        dispensasjonTypeId
        dispFra
        arealplan {
            planNavn
            planId
            planStatus
            planStatusId
            planType
            planTypeId
        }
        sak {
            sakAar,
            sakSeknr
        }
        vedtak
        vedtakId
        vedtaksdato,
        vertikalnivaId
        vertikalnivaType
        posisjon {
            x,
            y,
            z,
            koordsys
        }
    }
}
`