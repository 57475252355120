import moment from "moment"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { Behandling } from "../../../hooks/behandlinger/types"
import { formatDate } from "../../../utils/formatDate"
import { MidlertidigForbud } from "../../../hooks/midlertidigForbud/types"
import { find, orderBy } from "lodash-es"

export interface PlanNotificationHelperProps {
  plan: Arealplan
}

export type Horingsperiode = {
  fraDato: string
  tilDato: string
  behandlingsType: string
  behandling: Behandling
}

export default function usePlanNotificationHelpers() {
  const planTypeIdsWithoutKlagefrist: number[] = [20, 21]

  function showKlagefristWithoutDate({
    plan
  }: PlanNotificationHelperProps): boolean {
    return (
      !planTypeIdsWithoutKlagefrist.includes(plan.planTypeId) &&
      plan.planStatusId === 3 &&
      !!plan.meldingKlagefrist &&
      plan.iKraft &&
      !plan.klagefrist
    )
  }

  function showKlagefristWithDate({
    plan
  }: PlanNotificationHelperProps): boolean {
    return (
      !planTypeIdsWithoutKlagefrist.includes(plan.planTypeId) &&
      plan.planStatusId == 3 &&
      Boolean(plan.klagefrist) &&
      moment().startOf("day") <= moment(plan.klagefrist)
    )
  }
  function showKlagefristExpiredLessThanaWeek({
    plan
  }: PlanNotificationHelperProps): boolean {
    if (plan.klagefrist) {
      const today = new Date()
      const klagefristdate = new Date(plan.klagefrist)
      const oneweekafterklagefrist = new Date(plan.klagefrist).setDate(
        klagefristdate.getDate() + 7
      )

      return (
        today.setHours(0, 0, 0, 0) > klagefristdate.setHours(0, 0, 0, 0) &&
        today <= new Date(oneweekafterklagefrist)
      )
    }
    return false
  }

  function showKlageUtsatt({ plan }: PlanNotificationHelperProps): boolean {
    return plan.planStatusId === 6 && Boolean(plan.ubehandletKlage)
  }

  function showPlandokumentasjonIkkeOppdatert({
    plan
  }: PlanNotificationHelperProps): boolean {
    return plan.planStatusId === 3 && !plan.plandokumentasjonOppdatert
  }

  function showMidlertidigForbud(
    midlertidigForbud: MidlertidigForbud[] | undefined
  ): boolean {
    return midlertidigForbud !== undefined
  }

  function showMottattKlage({ plan }: PlanNotificationHelperProps): boolean {
    return plan.planStatusId === 3 && plan.gjenstaendeKlage
  }

  function showUavklartInnsigelse({
    plan
  }: PlanNotificationHelperProps): boolean {
    return plan.planStatusId === 6 && Boolean(plan.ubehandletInnsigelse)
  }

  function getAnnouncementDate({
    behandlinger
  }: {
    behandlinger: Behandling[]
  }): string {
    const behandling = find(orderBy(behandlinger, "fraDato", "desc"), {
      behandlingstypeId: 9
    }) as Behandling
    return behandling ? formatDate(behandling.fraDato) : ""
  }

  function getHoringsperioder({
    behandlinger
  }: {
    behandlinger?: Behandling[]
  }): Horingsperiode[] | null {
    if (behandlinger?.length) {
      const today = moment()
      const behandlingerFiltered = behandlinger.filter(
        b => b.fraDato && b.tilDato
      )
      const horingsperioder = orderBy(
        behandlingerFiltered,
        "FraDato",
        "desc"
      ).reduce((results, b) => {
        const behandling = b as Behandling
        const isInRange = today.isBetween(
          behandling.fraDato,
          behandling.tilDato,
          "day",
          "[]"
        )
        if (isInRange)
          results.push({
            fraDato: formatDate(behandling.fraDato),
            tilDato: formatDate(behandling.tilDato),
            behandlingsType: behandling.behandlingstype || "",
            behandling: b
          } as Horingsperiode)
        return results
      }, [] as Horingsperiode[])
      return horingsperioder.length > 0 ? horingsperioder : null
    }
    return null
  }

  function shouldShowHoringsperiode({
    plan
  }: PlanNotificationHelperProps): boolean {
    // sjekk om dagens dato er innenfor høringsperioden
    return (
      (plan.horingsfrist &&
        moment().isBetween(
          moment(plan.horingsStart),
          moment(plan.horingsfrist),
          "day"
        )) ||
      false
    )
  }

  return {
    showKlagefristWithoutDate,
    showKlagefristWithDate,
    showKlagefristExpiredLessThanaWeek,
    showKlageUtsatt,
    showPlandokumentasjonIkkeOppdatert,
    showMidlertidigForbud,
    showMottattKlage,
    showUavklartInnsigelse,
    getAnnouncementDate,
    getHoringsperioder,
    shouldShowHoringsperiode
  }
}
