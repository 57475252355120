import React from "react"
import { ArrowBack } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import MarkDown from "react-markdown"
import { helpObject } from "../../help/helpObject"
import Footer from "../footer"
import "./faq.css"
import { useNavigate } from "react-router-dom"

const Faq = () => {
  const navigate = useNavigate()
  return (
    <>
      <Box
        sx={{
          marginTop: "20px",
          marginLeft: "20px"
        }}>
        <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
          Tilbake
        </Button>
      </Box>
      <Box
        sx={{
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
          maxWidth: "1000px"
        }}>
        <MarkDown>{helpObject.info.omPlanregister}</MarkDown>
        <Box mb={60} />
        <MarkDown>{helpObject.info.begreper}</MarkDown>
        <Box mb={60} />
        <MarkDown>{helpObject.info.faq}</MarkDown>
        <Box mb={40} />
      </Box>
      <Footer />
    </>
  )
}
export default Faq
