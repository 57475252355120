import { useQuery } from "react-query"
import { fetchArealplanDispensasjoner } from "../../services/api"
import { ArealplanId } from "../arealplaner/types"
import useSelectedArealplanId from "../arealplaner/useSelectedArealplanId"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { Dispensasjon } from "./types"

const getDispensasjoner = async (arealplanId: ArealplanId) => {
  const { data } = await fetchArealplanDispensasjoner(arealplanId)
  return data
}

export default function useDispensasjoner() {
  const arealplanId = useSelectedArealplanId()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  const query = useQuery<Dispensasjon[]>(
    ["dispensasjoner", { kundeId, arealplanId }],
    () => getDispensasjoner(arealplanId!),
    {
      onError: notify,
      enabled: !!arealplanId
    }
  )

  return {
    ...query,
    data: query.data || []
  }
}
