import {
  FeatureAttribute,
  WmsFeature
} from "../../../../../../services/apiTjenestekatalogen"
import React, { useState } from "react"
import AttributeDetails from "./AttributeDetails"
import { Box } from "@mui/material"

import {ChevronDownIcon, ChevronRightIcon} from '@norkart/toi-icons';
import { gfiAttId } from "../../../../../../services/apiTjenestekatalogen"

type Props = {
  attributeItem: WmsFeature
  visibleGfiAtt?: gfiAttId
  zoomToDrawnLayer
  openAttribute
  setOpenAttribute
  setVisibleInMap: (id: gfiAttId, value: boolean) => void
}

const Attribute = ({
  zoomToDrawnLayer,
  attributeItem,
  setVisibleInMap,
  visibleGfiAtt,
  openAttribute,
  setOpenAttribute
}: Props) => {
  const getAttributeDetails = (
    attributeItem: WmsFeature
  ): FeatureAttribute[] => {
    const attributeDetails: FeatureAttribute[] = []
    if (attributeItem.AttributesTree) {
      addAttribute(attributeItem, attributeDetails)
    }
    return attributeDetails
  }

  const addAttribute = (
    attributeItem: FeatureAttribute | WmsFeature,
    attributeDetails: FeatureAttribute[]
  ) => {
    attributeItem.AttributesTree &&
      attributeItem.AttributesTree.forEach(att => {
        if (att.Value) attributeDetails.push(att)
        if (att.AttributesTree) {
          addAttribute(att, attributeDetails)
        }
      })
  }

  const onHeaderClick = () => {
    const isClosingMenu = openAttribute === attributeItem.id
    isClosingMenu
      ? setOpenAttribute(undefined)
      : setOpenAttribute(attributeItem.id)
    const gfiAttIdIsVisibleInMap =
      visibleGfiAtt && visibleGfiAtt === attributeItem.id
    const layerShouldBeVisibleInMap = !gfiAttIdIsVisibleInMap && !isClosingMenu
    setVisibleInMap(attributeItem.id, layerShouldBeVisibleInMap)
  }

  const displayDetails = openAttribute === attributeItem.id
  const details = getAttributeDetails(attributeItem)
  return (
    <Box
      sx={theme => ({
        paddingLeft: "15px",
        paddingRight: "10px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          width: "inherit"
        },
        button: {
          width: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "none",
          backgroundColor: "transparent",
          "&:focus": {
            outline: "none",
            textDecoration: "underline"
          },
          "&:hover": {
            textDecoration: "underline"
          }
        }
      })}>
      <button onClick={onHeaderClick}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <p
            style={{
              fontSize: "17px",
              marginBottom: 6
            }}>
            {attributeItem.Description}
          </p>
          {displayDetails ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </div>
      </button>
      {displayDetails && (
        <AttributeDetails
          zoomToDrawnLayer={zoomToDrawnLayer}
          visibleGfiAtt={visibleGfiAtt}
          gfiAttId={attributeItem.id}
          setVisibleInMap={setVisibleInMap}
          attributes={details}
        />
      )}
    </Box>
  )
}
export default Attribute
