import * as React from "react"
import TabbableTableLink from "../TabbableTableLink"
import { formatDate } from "../../utils/formatDate"
import { showDialog } from "../../store/dialog/actions"
import EditIcon from "@mui/icons-material/Edit"
import GenericTable from "../GenericTable"
import { GTableAction, GenericTableProps } from "../GenericTable/GenericTable"
import { Dokument } from "../../hooks/dokumenter/types"
import FileIcon from "../FileIcon"
import ShareIcon from "@mui/icons-material/Share"
import { getFileExtension } from "../../utils/getFileExtension"
import { Behandling } from "../../hooks/behandlinger/types"
import useCan from "../../utils/auth/useCan"
import useDokuments from "../../hooks/dokumenter/useDokuments"
import { Dispensasjon } from "../../hooks/dispensasjoner/types"
import ShareTextDialog from "../ShareTextDialog"
import { useDispatch } from "react-redux"
export interface DocumentTableProps extends Partial<GenericTableProps> {
  dokumenter: Dokument[]
  behandling?: Behandling
  dispensasjon?: Dispensasjon
}
function renderDocName(document: Dokument) {
  const { openDokument } = useDokuments()

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: 3 }}>
      <FileIcon extension={getFileExtension(document.dokumentnavn)} />
      <TabbableTableLink
        underline={false}
        text={document.dokumentnavn}
        action={() => openDokument(document.id)}
      />
    </div>
  )
}

const cellPadding = "4px 16px"
const columns = [
  {
    title: "Dokumenttype",
    field: "dokumenttype",
    cellStyle: {
      padding: cellPadding,
      whiteSpace: "nowrap"
    } as React.CSSProperties,
    headerStyle: {
      padding: cellPadding
    } as React.CSSProperties
  },
  {
    title: "Dokumentnavn",
    field: "dokumentnavn",
    cellStyle: {
      padding: cellPadding
    } as React.CSSProperties,
    headerStyle: {
      padding: cellPadding
    } as React.CSSProperties,
    render: renderDocName
  },
  {
    title: "Beskrivelse",
    field: "beskrivelse",
    cellStyle: {
      padding: cellPadding
    } as React.CSSProperties,
    headerStyle: {
      padding: cellPadding
    } as React.CSSProperties
  },
  {
    title: "Dato",
    field: "dokumentdato",
    cellStyle: {
      padding: cellPadding
    } as React.CSSProperties,
    headerStyle: {
      padding: cellPadding
    } as React.CSSProperties,
    render: dokument => formatDate(dokument.dokumentdato)
  }
]

function DocumentTable({ dokumenter, ...props }: DocumentTableProps) {
  const { openDokument } = useDokuments()
  const can = useCan()
  const [isOpen, setIsOpen] = React.useState(false)
  const [textToShare, setTextToShare] = React.useState("")
  const dispatch = useDispatch()

  const allowEdit = props.dispensasjon ? can.editDisp : can.edit

  function handleEdit(event, dokument) {
    dispatch(
      showDialog({
        dialogType: "EDIT_DOKUMENT",
        dialogProps: {
          dokument,
          behandling: props.behandling,
          dispensasjon: props.dispensasjon
        }
      })
    )
  }
  const handleShare = (event, dokument) => {
    if (dokument.url) {
      setTextToShare(dokument.url)
      setIsOpen(true)
    }
  }

  const actions = [] as GTableAction[]
  actions.push({
    icon: () => <ShareIcon />,
    tooltip: "Del",
    iconProps: {
      style: { color: "rgba(0, 0, 0, 0.54)" },
      fontSize: "small"
    },
    onClick: (event, dokument) => {
      handleShare(event, dokument)
    }
  } as GTableAction)

  if (allowEdit) {
    actions.push({
      icon: () => <EditIcon />,
      tooltip: "Endre dokument",
      iconProps: {
        style: { color: "rgba(0, 0, 0, 0.54)" },
        fontSize: "small"
      },
      onClick: (event, dokument) => {
        handleEdit(event, dokument)
      }
    } as GTableAction)
  }
  return (
    <div className="tabbable-table-wrapper">
      <GenericTable
        title="Dokumenter"
        data={dokumenter}
        idColumn="id"
        columns={columns}
        actions={actions}
        onRowClick={(event, rowData, togglePanel) => {
          openDokument(rowData.id)
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Ingen dokumenter"
          }
        }}
        {...props}
      />
      <ShareTextDialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        title={"Del link til dokumentet"}
        textToShare={textToShare}
      />
    </div>
  )
}

export default DocumentTable
