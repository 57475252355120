import config from "../config"
import axios, { CancelToken } from "axios"

const TOKEN = config.planregister.token
const TOKENSTRING = `?apitoken=${TOKEN}`
const BASE_URL = config.planregister.url + "/api"

export const fetchArealplanId = (
  planId: string,
  kommunenummer: string,
  customerId: string,
  cancelToken: CancelToken
) => {
  return axios.get(
    `${BASE_URL}/gi/kunder/${customerId}/arealplaner/id/${kommunenummer}/${planId}${TOKENSTRING}`,
    { cancelToken }
  )
}
