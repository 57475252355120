import React from "react"
import { FormLabel, styled } from "@mui/material"

type Props = { children?: React.ReactNode }

export const FilterLabel = styled((props: Props) => (
  <FormLabel component="legend" {...props} />
))({
  paddingBottom: 5,
  paddingTop: 10,
  fontWeight: 500,
  color: "black",
  letterSpacing: 0.8,
  display: "flex",
  alignItems: "center"
})

export default FilterLabel
