import React, { useEffect } from "react"
import qs from "query-string"
import { useParams } from "react-router"
import useSelectedKundeId from "../hooks/kunder/useSelectedKundeId"
import { UrlSearchParams } from "../store/search/types"
import { searchFiltersToQueryString } from "../utils/searchFiltersToQueryString"
import { openSnackbarMessage } from "../components/SnackbarMessage"
import { redirect, useNavigate } from "react-router-dom"

// // http://www.arealplaner.no/bergen1201/nki?funksjon=VisPlanerForEiendom&kommunenummer=1201&gaardsnummer=1&bruksnummer=2[&festenummer=0][&seksjonsnummer=1]
// http://localhost:3000/norkart0007/nki?funksjon=VisPlanerForEiendom&kommunenummer=4601&gaardsnummer=1&bruksnummer=2
// http://localhost:3000/norkart0007/nki?funksjon=VisPlanerForEiendom&kommunenummer=4601&gaardsnummer=1&bruksnummer=2&festenummer=0&seksjonsnummer=1
export const availableNkiFunctions = ["VisPlanerForEiendom"]

export const NKI = () => {
  const customerId = useSelectedKundeId()
  const navigate = useNavigate()

  useEffect(() => {
    if (customerId) onMount()
  }, [customerId])

  const onMount = () => {
    const {
      funksjon,
      kommunenummer: knr,
      gaardsnummer: gnr,
      bruksnummer: bnr,
      festenummer: fnr,
      seksjonsnummer: snr
    } = qs.parse(location.search)

    const filters: UrlSearchParams = {
      knr: knr as string,
      gnr: Number(gnr),
      bnr: Number(bnr),
      fnr: Number(fnr),
      snr: Number(snr)
    }
    const filtersQs = searchFiltersToQueryString(filters)
    if (!funksjon)
      openSnackbarMessage({
        variant: "error",
        message: "Ingen funksjon angitt"
      })
    if (!availableNkiFunctions.includes(funksjon as string))
      openSnackbarMessage({
        variant: "error",
        message: "Denne funksjonen er ikke støttet"
      })

    switch (funksjon) {
      case "VisPlanerForEiendom":
        if (!knr)
          openSnackbarMessage({
            variant: "error",
            message: "Parameter 'kommunenummer' er påkrevd"
          })
        if (!gnr)
          openSnackbarMessage({
            variant: "error",
            message: "Parameter 'gaardsnummer' er påkrevd"
          })
        if (!bnr)
          openSnackbarMessage({
            variant: "error",
            message: "Parameter 'bruksnummer' er påkrevd"
          })
        if (knr && gnr && bnr) {
          navigate(`/${customerId}/arealplaner/search?${filtersQs}`)
        }
    }
  }
  return null
}
