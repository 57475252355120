import { Reducer } from "@reduxjs/toolkit"
import { SessionActionTypes, SessionState, SetActivePlanTabAction, SetActiveSakIdAction, SetExpandBehandlingIdAction, SetExpandDispensasjonIdAction, UseAlternativeFrontpageAction } from "./types"

const initialState: SessionState = {
  activePlanTab: undefined,
  activeSakId: undefined,
  useAltFrontpage: false,
  expandDispensasjonId: undefined,
  expandBehandlingId: undefined
}

type KnownActions =
  | SetActivePlanTabAction
  | SetActiveSakIdAction
  | UseAlternativeFrontpageAction
  | SetExpandDispensasjonIdAction
  | SetExpandBehandlingIdAction;

const reducer: Reducer<SessionState, KnownActions> = (state = initialState, action) => {
  switch (action.type) {
    case SessionActionTypes.SET_ACTIVE_PLAN_TAB: {
      return { ...state, activePlanTab: action.payload }
    }

    case SessionActionTypes.SET_ACTIVE_SAK_ID: {
      return { ...state, activeSakId: action.payload }
    }

    case SessionActionTypes.USE_ALTERNATIVE_FRONTPAGE: {
      return { ...state, useAltFrontpage: action.payload }
    }

    case SessionActionTypes.SET_EXPAND_DISPENSASJON_ID: {
      return { ...state, expandDispensasjonId: action.payload }
    }

    case SessionActionTypes.SET_EXPAND_BEHANDLING_ID: {
      return { ...state, expandBehandlingId: action.payload }
    }

    default:
      return state
  }
}

export { reducer as sessionReducer }

export const getActivePlanTab = (state: SessionState) => state.activePlanTab
export const getActiveSakId = (state: SessionState) => state.activeSakId
export const getUseAltFrontpage = (state: SessionState) => state.useAltFrontpage
export const getExpandDispensasjonId = (state: SessionState) =>
  state.expandDispensasjonId
export const getExpandBehandlingId = (state: SessionState) =>
  state.expandBehandlingId
