import React from "react"
import { Chip, styled } from "@mui/material"
import { usePropertySearch } from "./filters/PropertySearchFilter/usePropertySearch"
import { useFilters } from "./filters/useFilters"

const ActiveFilterChip = styled(Chip)({
  borderRadius: 0,
  fontStyle: "italic",
  marginRight: "10px",
  marginBottom: "10px"
})

export default function ActiveFiltersList() {
  const filters = useFilters()
  const propertySearch = usePropertySearch()

  const deleteFilter = filter => {
    filters.remove(filter.type, filter.searchText)
  }

  return (
    <div className="active_filters">
      {filters.activeFiltersFromUrl?.map((filter, i) => (
        <ActiveFilterChip
          key={i}
          onDelete={e => deleteFilter(filter)}
          label={filter.label}
        />
      ))}
      {propertySearch.get() && (
        <ActiveFilterChip
          label={propertySearch.getPropertyLabel()}
          onDelete={() => propertySearch.clear()}
        />
      )}
    </div>
  )
}
