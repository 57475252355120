import { WmsGeometry } from "../../../services/apiTjenestekatalogen"
import { MapboxLayer } from "@norkart/nkm-mapbox-map"
import { Geometry, Polygon, Point, LineString } from "geojson"

type LatLngArray = [number, number]

export const getMapboxLayer = (
  geometry: WmsGeometry | null | undefined,
  interiorGeometries: undefined | null | WmsGeometry[],
  id: string,
  paint?: MapboxLayer["paint"],
  beforeLayerId?: string
) => {
  let outer: LatLngArray[] = []
  let interiors: LatLngArray[][] = []

  if (geometry) {
    outer = toLatLngArray(geometry)
  }
  if (interiorGeometries) {
    interiorGeometries.forEach(interior => {
      interiors.push(toLatLngArray(interior))
    })
  }
  const geojsonGeom: Geometry = getGeometry(outer, interiors)
  return formatToMapboxLayer(geojsonGeom, id, paint, beforeLayerId)
}

const getGeometry = (
  outer: LatLngArray[],
  interiors: LatLngArray[][]
): Geometry => {
  const typeGeometry = chooseGeometryFromCoordinates(outer, interiors)
  return createGeojsonGeom(typeGeometry, outer, interiors)
}

const createGeojsonGeom = (
  typeGeometry: "PolygonWithHoles" | "Point" | "Polygon" | "LineString",
  outer: LatLngArray[],
  interiors: LatLngArray[][]
): Point | Polygon | LineString => {
  if (typeGeometry === "Point") {
    const pointCoords: Point["coordinates"] = outer[0]
    return {
      type: "Point",
      coordinates: pointCoords
    }
  } else if (typeGeometry === "Polygon") {
    const polCoords: Polygon["coordinates"] = [outer]
    return {
      type: "Polygon",
      coordinates: polCoords
    }
  } else if (typeGeometry === "LineString") {
    const LineCoords: LineString["coordinates"] = outer
    return {
      type: "LineString",
      coordinates: LineCoords
    }
  } else {
    return createPolygonWithHoles(outer, interiors)
  }
}

const createPolygonWithHoles = (
  outer: LatLngArray[],
  interiors: LatLngArray[][]
): Polygon => {
  /* HOW GEOJSON WITH WHOLES ARE BUILT
      "coordinates": [
        [
          {polygon},
          [
            {hole},
            {hole},
            {hole}
          ]
        ]
      ]*/

  //For testing, Plan with 64040000 id has holes
  const combinedCoordsArray: GeoJSON.Position[][] = [outer].concat(interiors)
  const geom: Polygon = {
    type: "Polygon",
    coordinates: combinedCoordsArray
  }
  return geom
}

const toLatLngArray = (wmsGeometry: WmsGeometry): LatLngArray[] => {
  return wmsGeometry.Positions.map(xy => {
    return [xy.X, xy.Y]
  })
}

function chooseGeometryFromCoordinates(
  latlngArray: LatLngArray[],
  interiors: LatLngArray[][]
) {
  if (interiors.length > 0) return "PolygonWithHoles"
  else if (latlngArray.length === 1) return "Point"
  else if (
    latlngArray.length > 2 &&
    latlngArray[0][0] === latlngArray[latlngArray.length - 1][0] &&
    latlngArray[0][1] === latlngArray[latlngArray.length - 1][1]
  )
    return "Polygon"
  else return "LineString"
}

export const formatToMapboxLayer = (
  geom: Geometry,
  id: string,
  overWritePaint?: MapboxLayer["paint"],
  beforeLayerId?: string,
  overwriteLayerType?: string
): MapboxLayer => {
  let mapboxLayerType
  let paint: MapboxLayer["paint"]
  if (geom.type === "Point") {
    mapboxLayerType = "symbol"
    paint = {}
  } else if (geom.type === "Polygon" || geom.type === "MultiPolygon") {
    mapboxLayerType = "fill"
    paint = {
      "fill-color": "#102cf7",
      "fill-outline-color": "#102cf7",
      "fill-opacity": 0.4
    }
  } else if (geom.type === "LineString") {
    mapboxLayerType = "line"
    paint = {
      "line-color": "#102cf7",
      "line-width": 3
    }
  }
  if (overwriteLayerType) mapboxLayerType = overwriteLayerType
  if (overWritePaint) paint = overWritePaint

  return {
    id: id,
    type: mapboxLayerType,
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: geom,
        properties: {}
      }
    },
    properties: beforeLayerId ? { beforeLayerId: beforeLayerId } : {},
    layout: {},
    paint: paint
  }
}
