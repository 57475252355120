// Lite komponent for å samle ett array til ett "and" filter for graphql, kanskje utvides til å kunne bruke feks "or" og.
//Inneholder en funksjon for å sende inn nytt array av filterobjekter, og ett objekt som er det nye filteret

import * as React from "react"

export type FilterProps = {
  filter?: any[]
}

export function useFilterQL() {
  const [filters, setFilters] = React.useState<any[] | undefined>()
  const [inputParams, setInputParams] = React.useState<any[] | undefined>()
  const [filterReturn, setFilterReturn] = React.useState<any | undefined>()
  const [inputParamsReturn, setInputParamsReturn] = React.useState<
    any | undefined
  >()

  //Brukes for filtrering av graphql-queries (where: DataFilterInput)
  const updateFilter = (value?: any[]) => {
    setFilters(value)
  }

  React.useMemo(() => {
    if (filters) setFilterReturn({ and: filters })
    else setFilterReturn(undefined)
  }, [filters])

  //Kan brukes om graphql queryen har inputparametere (feks GetDataQuery(int id))
  const updateInputParams = (value: any) => {
    setInputParams(value)
  }

  React.useMemo(() => {
    if (inputParams) setInputParamsReturn(inputParams)
    else setInputParamsReturn(undefined)
  }, [inputParams])

  return { inputParamsReturn, filterReturn, updateFilter, updateInputParams }
}
