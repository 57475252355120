import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box } from "@mui/material"
import ExpansionPanelMU from "@mui/material/Accordion"
import ExpansionPanelDetails from "@mui/material/AccordionDetails"
import ExpansionPanelSummary from "@mui/material/AccordionSummary"
import * as React from "react"
import HelpButton from "../HelpButton/HelpButton"

export interface ExpansionPanelProps {
  title?: string
  helpButtonText?: string
  extraInfo?: string | JSX.Element
  bgColor?: string
  bgColorClosed?: string
  bgColorOpen?: string
  padding?: string
  margin?: string
  children?: React.ReactNode
  showBorderWhenOpen?: boolean
  expandedOptions?: {
    onChange?: (isExpanded: boolean) => void
    expandedId?: number | string
    rowId?: number | string
    allowMultipleExpanded?: Boolean
  }
}

export interface ExpansionPanelState {
  bgColor: string
  bgColorClosed: string
  bgColorOpen: string
  withBorder: boolean
  elemRef: any
}

const ExpansionPanel = ({
  bgColor,
  bgColorOpen,
  bgColorClosed,
  title,
  helpButtonText,
  extraInfo,
  padding,
  margin,
  children,
  showBorderWhenOpen,
  expandedOptions
}: ExpansionPanelProps) => {
  const [state, setState] = React.useState<ExpansionPanelState>({
    bgColor: bgColor || "",
    bgColorClosed: bgColorClosed || bgColor || "",
    bgColorOpen: bgColorOpen || bgColor || "",
    withBorder: false,
    elemRef: React.createRef()
  })

  const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setState({
      ...state,
      bgColor: isExpanded ? state.bgColorOpen : state.bgColorClosed,
      withBorder: Boolean(isExpanded && showBorderWhenOpen)
    })
    if (state.elemRef && state.elemRef.current) {
      state.elemRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth"
      })
    }
    if (expandedOptions && expandedOptions.onChange)
      expandedOptions.onChange(isExpanded)
  }
  let extraProps: any = {
    onChange: handleChange
  }
  const supportsOnlyOneExpanded =
    expandedOptions &&
    !expandedOptions.allowMultipleExpanded &&
    expandedOptions.rowId
  if (expandedOptions && supportsOnlyOneExpanded) {
    extraProps.expanded = expandedOptions.expandedId === expandedOptions.rowId
    if (extraProps.expanded && !state.withBorder) {
      setState({
        ...state,
        withBorder: Boolean(showBorderWhenOpen)
      })
    } else if (!extraProps.expanded && state.withBorder) {
      setState({
        ...state,
        withBorder: false
      })
    }
  }
  return (
    <ExpansionPanelMU
      style={{
        width: "100%",
        background: state.bgColor
      }}
      sx={theme => ({
        ...(state.withBorder && {
          border: "2px solid " + theme.palette.secondary.main
        })
      })}
      {...extraProps}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: "inline-block", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {title && <Box fontWeight="fontWeightBold">{title}</Box>}
            {helpButtonText && (
              <HelpButton helpText={helpButtonText} type={"ExpansionPanelMU"} />
            )}
          </div>
          {extraInfo && extraInfo}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{
          padding: padding || "10px",
          margin: margin || ""
        }}>
        <div ref={state.elemRef} style={{ width: "100%" }}>
          {children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanelMU>
  )
}

export default ExpansionPanel
