import * as React from "react"
import "./bodystyle.css"
import OpenDocument from "./features/dokumenter/OpenDocument"
import useSelectedKundeId from "./hooks/kunder/useSelectedKundeId"
import AppRoutes from "./routes/AppRoutes"
import { setTitle } from "./utils/setTitle"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import ApplicationInsightsInitializer from "./AppInsights"
import { Routes, Route } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { resetAuthState } from "./store/auth/actions"
import { useAuth } from "./utils/auth"

export function Application() {
  setTitle()
  const dispatch = useDispatch()
  const customer = useSelectedKundeId()
  const auth = useAuth()
  const appInsights = ApplicationInsightsInitializer(
    auth.authorization.isAuthorized ? "true" : undefined,
    customer
  )

  useEffect(() => {
    auth.silentLogin()
    return () => {
      dispatch(resetAuthState())
    }
  }, [])

  return (
    <AppInsightsContext.Provider value={appInsights}>
      <Routes>
        <Route
          path="/:customerId/dokumenter/:dokumentId/:dokumentNavn"
          element={<OpenDocument />}
        />
        <Route path="/*" element={<AppRoutes />} />
      </Routes>
    </AppInsightsContext.Provider>
  )
}

export default Application
