import * as React from "react"
import { Button } from "@mui/material"

export interface ActionButtonProps {
  title: string | JSX.Element
  label: string
  onClick: (event) => any
}

function ActionButton({ title, onClick, label }: ActionButtonProps) {
  return (
    <Button onClick={onClick} variant="outlined" aria-label={label}>
      {title}
    </Button>
  )
}

export default ActionButton
