import EditIcon from "@mui/icons-material/Edit"
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material"
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table"
import * as React from "react"
import { Innstilling } from "../../../hooks/meta/types"
import FrontendSettingDialog from "./FrontendSettingDialog"

interface FrontendSettingsTableProps {
  innstillinger: Innstilling[] | undefined
}

export function FrontendSettingsTable({
  innstillinger
}: FrontendSettingsTableProps) {
  const columns = React.useMemo<ColumnDef<Innstilling>[]>(
    () => [
      {
        accessorKey: "beskrivelse",
        header: () => "Innstilling",
        enableSorting: true
      },
      {
        accessorKey: "verdi",
        header: () => "Verdi",
        enableSorting: false
      },
      {
        id: "editColumn",
        header: () => "",
        cell: r => editVerdiButton(r.row.original)
      }
    ],
    []
  )

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [data, setData] = React.useState<Innstilling[] | undefined>()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [
    selectedInnstilling,
    setSelectedInnstilling
  ] = React.useState<Innstilling>()

  const editVerdiButton = (row: Innstilling | undefined) => {
    if (row)
      return (
        <IconButton
          onClick={() => {
            handleEdit(row)
          }}>
          <EditIcon />
        </IconButton>
      )
  }

  const handleEdit = (innstilling: Innstilling) => {
    setDialogOpen(true)
    setSelectedInnstilling(innstilling)
  }

  React.useEffect(() => {
    setData(innstillinger)
  }, [innstillinger])

  const table = useReactTable({
    data: data ? data : [],
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })
  if (data)
    return (
      <div>
        <Table
          sx={{
            "& tbody tr:last-child td": {
              border: 0
            }
          }}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell
                    onClick={
                      header.column.getCanSort()
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                    sx={{
                      fontSize: "1rem",
                      cursor: header.column.getCanSort() ? "pointer" : "auto"
                    }}
                    key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getIsSorted() === "asc" ? (
                      <TableSortLabel active={true} direction="asc" />
                    ) : header.column.getIsSorted() === "desc" ? (
                      <TableSortLabel active={true} direction="desc" />
                    ) : undefined}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                sx={{
                  fontSize: "1rem"
                }}
                hover={true}
                key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    sx={{
                      fontSize: "1rem",
                      textAlign:
                        cell.column.id === "editColumn" ? "right" : "left"
                    }}
                    key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <FrontendSettingDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          innstilling={selectedInnstilling}
        />
      </div>
    )
  return (
    <p style={{ paddingLeft: "15px" }}>
      Ingen innstillinger tilgjengelig for redigering
    </p>
  )
}
