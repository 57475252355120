export interface SharedState {
  errors?: string
}

export interface Vertikalniva {
  id: number
  beskrivelse: string
}

export enum SharedStateActions {
  GENERAL_ERROR = "@@planregister/shared/GENERAL_ERROR"
}
