import { Behandling } from "../../hooks/behandlinger/types"
import useMeta from "../../hooks/meta/useMeta"
import useBehandlingFormHelpers from "../behandling/BehandlingForm/useBehandlingFormHelpers"

export default function useDocumentHelpers() {
  const meta = useMeta()
  const behandlingFormHelpers = useBehandlingFormHelpers()

  const shouldBestemmelserBeActive = dokumenttypeId => dokumenttypeId == 5
  const shouldHoringsdokumentBeActive = (behandling?: Behandling) => {
    if (!behandling) return false
    return behandlingFormHelpers.shouldHaveTwoDateInputs(
      behandling.planProsessId
    )
  }
  const getDefaultTilgangId = (behandling?: Behandling) => {
    let tilgangId = 1
    if (behandling) {
      tilgangId = behandling.tilgangId || tilgangId
    }
    return tilgangId
  }
  const getAvailableTilgangstyper = (behandling?: Behandling) => {
    let tilgangstyper = meta.data.tilgangstyper
    if (behandling) {
      tilgangstyper = meta.data.tilgangstyper.filter(
        t => t.id >= getDefaultTilgangId(behandling)
      )
    }
    return tilgangstyper
  }
  return {
    shouldBestemmelserBeActive,
    shouldHoringsdokumentBeActive,
    getDefaultTilgangId,
    getAvailableTilgangstyper
  }
}
