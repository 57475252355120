import { Box, List, ListItem, ListItemText, ListSubheader } from "@mui/material"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Sak } from "../../../hooks/arealplaner/types"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import { getActiveSakId } from "../../../store"
import { setActiveSakId } from "../../../store/session/actions"
import { formatSaksnr } from "../../../utils/formatSaksnr"

type Props = {
  onClick?: (sak: Sak) => void
}

function SakSelector(props: Props) {
  const dispatch = useDispatch()
  const arealplan = useArealplan()
  const selectedSakId = useSelector(getActiveSakId)
  const saker = arealplan.data.saker

  React.useEffect(() => {
    if (saker) {
      const defaultSakId = saker[0]?.id
      const found = saker.some(sak => sak.id === selectedSakId)
      if (!found) dispatch(setActiveSakId(defaultSakId))
    }
  }, [arealplan.data.saker])

  const onClick = (sak: Sak) => {
    dispatch(setActiveSakId(sak?.id))
    props.onClick && props.onClick(sak)
  }

  const isSelected = (sak: Sak) => {
    return selectedSakId === sak.id
  }

  if (saker?.length && saker?.length <= 1) return null

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.background.paper
      })}>
      <List component="nav">
        <ListSubheader>Hvilken sak vil du hente fra?</ListSubheader>
        {saker.map((sak, i) => (
          <ListItem
            key={i}
            button={true}
            onClick={() => onClick(sak)}
            selected={isSelected(sak)}>
            <ListItemText>
              {formatSaksnr(sak.sakAar, sak.sakSeknr)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default SakSelector
