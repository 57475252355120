import { AxiosResponse } from "axios"

export default function getFilenameFromResponse(
  response: AxiosResponse<any>
): string {
  let headerLine = response.headers["content-disposition"]
  let startFileNameIndex = headerLine.indexOf('"') + 1
  let endFileNameIndex = headerLine.lastIndexOf('"')
  let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)

  // Support different filename in content disposition
  if (filename === "") {
    let startFileNameIndex = headerLine.indexOf("filename=") + 9
    let endFileNameIndex = headerLine.lastIndexOf("; filename*=")
    filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
  }

  return filename
}
