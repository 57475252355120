import { DialogType } from "../../components/DialogRoot/DialogRoot"

export interface DialogState {
  dialogType: DialogType | null
  dialogProps?: any
  open?: boolean
}

export interface ShowDialogAction {
  type: typeof DialogActionTypes.SHOW_DIALOG;
  payload: {
    dialogType: DialogType | null;
    dialogProps: any;
  };
}

export interface HideDialogAction {
  type: typeof DialogActionTypes.HIDE_DIALOG;
}

export enum DialogActionTypes {
  SHOW_DIALOG = "@@planregister/dialog/SHOW_DIALOG",
  HIDE_DIALOG = "@@planregister/dialog/HIDE_DIALOG"
}
