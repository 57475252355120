
import * as React from "react"
import { Sak } from "../../../hooks/arealplaner/types"
import SakItem from "./SakItem"
import { sortBy } from "lodash-es"

export interface SakListProps {
  saker: Partial<Sak>[]
}

function SakList({ saker = [] }: SakListProps) {
  return (
    <>
      {sortBy(saker, ["sakAar", "sakSeknr"]).map((sak, index) => (
        <span key={index}>
          <SakItem sak={sak} />
          {index < saker.length - 1 && ", "}
        </span>
      ))}
    </>
  )
}

export default SakList
