export const validateUtmCoordsFormat = (
  coordsSelected: { x: number; y: number } | undefined
) => {
  if (coordsSelected?.x && coordsSelected?.y) {
    //RegEx som validerer UTM-koordinater - ikke N og Ø sammen, men hver for seg.
    const regexCoords = /^(\-?\d+)(\.?\d{1})$/

    const xFormatValidation = coordsSelected?.x?.toString().match(regexCoords)
    const yFormatValidation = coordsSelected?.y?.toString().match(regexCoords)
    const coordsFormatValidationResult =
      xFormatValidation && yFormatValidation
        ? { x: true, y: true }
        : !xFormatValidation && yFormatValidation
        ? { x: false, y: true }
        : xFormatValidation && !yFormatValidation
        ? { x: true, y: false }
        : { x: false, y: false }

    return coordsFormatValidationResult
  } else return { x: false, y: false }
}
