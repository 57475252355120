import React from "react"
import { usePropertySearch } from "./usePropertySearch"

export const PropertyGeometryMissing = () => {
  const propertySearch = usePropertySearch()
  const activePropertyFilter = propertySearch.get()

  return (
    <div>
      {activePropertyFilter &&
        propertySearch.propertyGeometryIsMissing !== undefined &&
        (propertySearch.propertyGeometryIsMissing ? (
          <p style={{ fontStyle: "italic" }}>
            Eiendommen du søker på har ingen geometri i matrikkelen. Vi finner
            derfor ingen planer innenfor denne eiendommen.
          </p>
        ) : (
          <>
            <p style={{ fontStyle: "italic" }}>
              Planer som overlapper eiendommen mindre enn 1m<sup>2</sup> vises
              ikke i dette resultatet
            </p>
          </>
        ))}
    </div>
  )
}
