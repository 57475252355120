import * as React from "react"
import { Typography } from "@mui/material"
export interface ServerErrorProps {}

export interface ServerErrorState {}

class ServerError extends React.PureComponent<
  ServerErrorProps,
  ServerErrorState
> {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}>
        <div
          style={{
            width: "500px",

            // border: "1px solid black",
            padding: "60px"
          }}>
          <Typography
            variant="h1"
            style={{ marginBottom: "30px", fontSize: "2rem" }}>
            En feil har oppstått
          </Typography>
          <Typography style={{ marginBottom: "20px", fontSize: "1.5rem" }}>
            Vi jobber med å finne en løsning på problemet og beklager ulempen
            dette medfører.
          </Typography>
          <Typography style={{ fontSize: "1.5rem" }}>
            Prøv igjen om kort tid.
          </Typography>
        </div>
      </div>
    )
  }
}

export default ServerError
