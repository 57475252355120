export const toMoreMapFunctions = (plan, visplanUrl: string | undefined) => {
  if (plan)
    if (visplanUrl?.includes("?")) {
      window.open(
        `${visplanUrl}&funksjon=visplan&kommunenummer=${plan.komnr}&planidentifikasjon=${plan.planId}`,
        "_blank"
      )
    } else {
      window.open(
        `${visplanUrl}?funksjon=visplan&kommunenummer=${plan.komnr}&planidentifikasjon=${plan.planId}`,
        "_blank"
      )
    }
}
