import { useMutation, useQueryClient } from "react-query"
import { deleteToken } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { Token, TokenType } from "./types"

export default function useDeleteToken(tokenType: TokenType) {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  return useMutation(deleteToken, {
    onError: notify,
    onSuccess: () => {
      queryClient.invalidateQueries(["tokens", { kundeId, tokenType }])
    }
  })
}
