import { Box } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import ActionButton from "../../../components/ActionButtons/ActionButton"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { Token, TokenType } from "../../../hooks/tokens/types"
import useTokens from "../../../hooks/tokens/useTokens"
import TokenDialog from "./TokenDialog"
import TokenShareDialog from "./TokenShareDialog"
import TokensTable from "./TokensTable"

type Props = {
  tokenType: TokenType
  description: string | React.ReactNode
}

function TokensTab({ tokenType, description }: Props) {
  const tokens = useTokens(tokenType)
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(
    undefined
  )

  useEffect(() => {
    if (!tokenDialogOpen && !shareDialogOpen) setSelectedToken(undefined)
  }, [tokenDialogOpen, shareDialogOpen])

  const handleShareDialogClose = useCallback(
    () => setShareDialogOpen(false),
    []
  )

  const handleShare = (token: Token) => {
    setSelectedToken(token)
    setShareDialogOpen(true)
  }

  const handleTokenDialogClose = useCallback(
    () => setTokenDialogOpen(false),
    []
  )
  const handleEdit = (token: Token) => {
    setSelectedToken(token)
    setTokenDialogOpen(true)
  }
  const handleAdd = () => {
    setSelectedToken(undefined)
    setTokenDialogOpen(true)
  }

  if (tokens.isLoading) return <LoadingSpinner />

  return (
    <div>
      {description}
      <Box marginBottom={10} marginTop={10}>
        <ActionButton
          title={
            <>
              <AddIcon /> Ny tilgang
            </>
          }
          label={"Ny tilgang"}
          onClick={handleAdd}
        />
      </Box>

      <TokensTable
        data={tokens.data}
        handleShare={handleShare}
        handleEdit={handleEdit}
      />
      <TokenDialog
        isOpen={tokenDialogOpen}
        token={selectedToken}
        tokenType={tokenType}
        handleClose={handleTokenDialogClose}
      />
      {selectedToken && (
        <TokenShareDialog
          tokenType={tokenType}
          token={selectedToken}
          isOpen={shareDialogOpen}
          handleClose={handleShareDialogClose}
        />
      )}
    </div>
  )
}

export default TokensTab
