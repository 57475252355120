import { Box, Grid, Paper, styled, Typography } from "@mui/material"
import * as React from "react"
import ActionButtons from "../../../components/ActionButtons"
import ExpansionPanel from "../../../components/ExpansionPanel"
import HelpButton from "../../../components/HelpButton/HelpButton"
import HelpText from "../../../components/HelpText/HelpText"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import { helpObject } from "../../../help/helpObject"
import { Planforhold } from "../../../hooks/arealplaner/types"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import { showDialog } from "../../../store/dialog/actions"
import useCan from "../../../utils/auth/useCan"
import { formatDate } from "../../../utils/formatDate"
import { getBaseUrl } from "../../../utils/getBaseUrl"
import { useDispatch } from "react-redux"
import ReactTable from "../../../components/ReactTable"
import { createColumnHelper } from "@tanstack/react-table"
import { Link } from "react-router-dom"
import { groupBy } from "lodash-es"

export interface PlanRelationshipsProps {}

const PropertyWrapper = styled("div")({ padding: "3px 0" })
const PropertyHeader = styled(Typography)({ fontWeight: 500 })

const columnHelper = createColumnHelper<Planforhold>()

const reactTableColumns = [
  columnHelper.accessor('planforholdtype', {
    id: 'planforholdtype',
    header: 'Type planforhold',
    cell: props => props.getValue()
  }),
  columnHelper.accessor('planId', {
    id: 'planId',
    header: 'Planid',
    cell: props => props.getValue()
  }),
  columnHelper.accessor('planNavn', {
    id: 'planNavn',
    header: 'Plannavn',
    cell: props => <Link to={`${getBaseUrl()}/${props.row.original.arealplanId}`}>{props.getValue()}</Link>
  }),
  columnHelper.accessor('iKraft', {
    id: 'iKraft',
    header: 'Ikrafttredelsesdato',
    cell: props => formatDate(props.getValue())
  }),
  columnHelper.accessor('planType', {
    id: 'planType',
    header: 'Plantype',
    cell: props => props.getValue()
  }),
  columnHelper.accessor('planStatus', {
    id: 'planStatus',
    header: 'Planstatus',
    cell: props => props.getValue()
  })
]

const PlanRelationships: React.FC<
  PlanRelationshipsProps
> = ({}: PlanRelationshipsProps) => {
  const can = useCan()
  const dispatch = useDispatch()
  const { data: plan } = useArealplan()
  const [expandedId, setExpandedId] = React.useState<
   string | undefined
  >()

  function handleRowClick(arealplanId) {
    window.open(`${getBaseUrl()}/${arealplanId}`)
  }
  const onChange = (isExpanded: boolean, rowIndex: string) => {
    if (isExpanded) {
      setExpandedId(rowIndex)
    } else if (expandedId && !isExpanded) {
      setExpandedId(undefined)
    }
  }

  interface PlanforholdTypeData {
    planforholdType: string
    data: Planforhold[]
  }

  interface TableData extends Partial<Planforhold>{
    subRows?: Partial<Planforhold>[]
  }

  const mappedTableData = React.useMemo(() => {
    const dataToMap: Partial<Planforhold>[] = [...plan.planforholdForlopere, ...plan.planforholdEtterkommere]
    const result: TableData[] = []
    
    dataToMap.forEach(planforhold => {
      const planforholdHeader = result.find((e) => e.planforholdtype === planforhold.planforholdtype )
      if(planforholdHeader){
        if(planforholdHeader.subRows){
          const planforholdCopy = {...planforhold}
          planforholdCopy.planforholdtype = undefined
          planforholdHeader.subRows = [...planforholdHeader.subRows, planforholdCopy]
        }
      } else {
        const tableData = {planforholdtype: planforhold.planforholdtype} as TableData
        const planforholdCopy = {...planforhold}
        planforholdCopy.planforholdtype = undefined
        tableData.subRows = [planforholdCopy]
        result.push(tableData)
      }
    });

    return result
  }, [plan])

  const renderPlanforhold = (data) => {
    const entries = Object.entries(groupBy(data, "planforholdtype"))
    let planforholdtypedata = [] as PlanforholdTypeData[]
    for (const [planforholdtype, planforhold] of entries) {
      planforholdtypedata.push({
        planforholdType: planforholdtype,
        data: planforhold
      })
    }

    return (
      <>
        <MobileContainer>
          {planforholdtypedata.map((entry, index) => (
            <ExpansionPanel
              title={`${entry.planforholdType} (${entry.data.length})`}
              key={entry.planforholdType}
              showBorderWhenOpen={true}
              expandedOptions={{
                allowMultipleExpanded: false,
                rowId: entry.planforholdType,
                onChange: isExpanded =>
                  onChange(isExpanded, entry.planforholdType),
                expandedId: expandedId
              }}
              bgColor="white"
              padding="0"
              margin="0px">
              {entry.data.map(pf => (
                <Paper
                  key={pf.arealplanId}
                  square={true}
                  style={{ margin: "10px", background: "default" }}>
                  <Grid
                    container={true}
                    justifyContent="space-between"
                    alignItems="stretch"
                    wrap="nowrap"
                    onClick={() => handleRowClick(pf.arealplanId)}
                    key={pf.arealplanId}>
                    <div style={{ paddingLeft: "20px" }}>
                      {pf.planId && (
                        <PropertyWrapper>
                          <PropertyHeader>Planid:</PropertyHeader>
                          <Typography style={{ textDecoration: "underline" }}>
                            {pf.planId}
                          </Typography>
                        </PropertyWrapper>
                      )}
                      {pf.planNavn && (
                        <PropertyWrapper>
                          <PropertyHeader>Plannavn:</PropertyHeader>
                          <Typography>{pf.planNavn}</Typography>
                        </PropertyWrapper>
                      )}
                      {pf.planType && (
                        <PropertyWrapper>
                          <PropertyHeader>Plantype:</PropertyHeader>
                          <Typography>{pf.planType}</Typography>
                        </PropertyWrapper>
                      )}
                      {pf.iKraft && (
                        <PropertyWrapper>
                          <PropertyHeader>Ikrafttredelsesdato:</PropertyHeader>
                          <Typography>{formatDate(pf.iKraft)}</Typography>
                        </PropertyWrapper>
                      )}
                      {pf.planStatus && (
                        <PropertyWrapper>
                          <PropertyHeader>Planstatus:</PropertyHeader>
                          <Typography>{pf.planStatus}</Typography>
                        </PropertyWrapper>
                      )}
                    </div>
                  </Grid>
                </Paper>
              ))}
            </ExpansionPanel>
          ))}
        </MobileContainer>
        <DesktopContainer>
          <ReactTable
            columns={reactTableColumns}
            data={data}
            expandEnabled={true}
            noDataText="Ingen planforhold funnet"
            enableColorIndicator={false}
          />
        </DesktopContainer>
      </>
    )
  }
  return (
    <>
      <MobileContainer>
        <ExpansionPanel
          helpButtonText={helpObject.Faner.Planforhold.MeromPlanforhold}
          title={`Planforhold (${
            plan.planforholdEtterkommere.length +
            plan.planforholdForlopere.length
          })`}>
          {renderPlanforhold(plan.planforholdForlopere)}
          {renderPlanforhold(plan.planforholdEtterkommere)}
        </ExpansionPanel>
      </MobileContainer>
      <DesktopContainer>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "5px",
            paddingBottom: "10px",
            borderBottom: "1px solid lightgray"
          }}>
          <Grid
            container={true}
            direction={"column"}
            style={{ display: "flex", marginBottom: 40 }}>
            <HelpText
              style={{ marginRight: 50 }}
              helpText={helpObject.Faner.Planforhold.Planforhold}
            />
            <HelpButton
              helpText={helpObject.Faner.Planforhold.MeromPlanforhold}
              type="MeromPlanforhold"
              buttonVariant="tekst"
            />
          </Grid>
          {can.edit && (
            <ActionButtons
              actionButtons={[
                {
                  label: "Endre planforhold",
                  title: "Endre planforhold",
                  onClick: () =>
                    dispatch(
                      showDialog({
                        dialogType: "EDIT_PLAN_RELATIONSHIPS",
                        dialogProps: { plan }
                      })
                    )
                }
              ]}
            />
          )}
          {mappedTableData.length > 0 && (
            <>{renderPlanforhold(mappedTableData)}</>
          )}

          <div style={{ height: "50px" }} />

          
        </Box>
      </DesktopContainer>
    </>
  )
}

export default PlanRelationships
