import _ from "lodash-es"
import * as actions from "./actions"
import { FileImportActionTypes, FileImportState } from "./types"
import { combineReducers, Reducer } from "@reduxjs/toolkit"

const initialState: FileImportState = {
  files: [],
  journalDocumentSelection: [],
  errors: null
}

type FileImportAction =
  | ReturnType<typeof actions.addFile>
  | ReturnType<typeof actions.addFile>
  | ReturnType<typeof actions.removeFile>
  | ReturnType<typeof actions.updateFile>
  | ReturnType<typeof actions.reset>
  | ReturnType<typeof actions.updateErrors>
  | ReturnType<typeof actions.resetErrorForField>
  | ReturnType<typeof actions.resetErrorForFile>
  | ReturnType<typeof actions.addJournalDocument>
  | ReturnType<typeof actions.removeJournalDocument>

const files: Reducer<typeof initialState.files, FileImportAction> = (
  state = initialState.files,
  action
) => {
  switch (action.type) {
    case FileImportActionTypes.ADD_FILE: {
      const updatedFiles = [
        ...state.filter(file => file.id != action.payload.id),
        action.payload
      ]
      return updatedFiles
    }
    case FileImportActionTypes.REMOVE_FILE: {
      return state.filter(file => file.id != action.payload.id)
    }
    case FileImportActionTypes.UPDATE_FILE: {
      const updatedFiles = state.map(file => {
        if (file.id === action.payload.id) return action.payload
        return file
      })
      return updatedFiles
    }

    case FileImportActionTypes.RESET_FILE_IMPORT: {
      state.filter(file => file.url).map(file => URL.revokeObjectURL(file.url!))
      return initialState.files
    }
    default:
      return state
  }
}

const journalDocumentSelection: Reducer<
  typeof initialState.journalDocumentSelection,
  FileImportAction
> = (state = initialState.journalDocumentSelection, action) => {
  switch (action.type) {
    case FileImportActionTypes.ADD_JOURNAL_DOCUMENT: {
      const updatedFiles = [
        ...state.filter(file => file.systemId != action.payload.systemId),
        action.payload
      ]
      return updatedFiles
    }
    case FileImportActionTypes.REMOVE_JOURNAL_DOCUMENT: {
      return state.filter(file => file.systemId != action.payload.systemId)
    }
    case FileImportActionTypes.RESET_FILE_IMPORT:
      return initialState.journalDocumentSelection
    default:
      return state
  }
}

const errors: Reducer<typeof initialState.errors, FileImportAction> = (
  state = initialState.errors,
  action
) => {
  switch (action.type) {
    case FileImportActionTypes.UPDATE_ERRORS: {
      return action.payload
    }
    case FileImportActionTypes.RESET_ERROR_FOR_FIELD: {
      const rowErrors = state && state[action.payload.data.id]
      const newRowErrors = _.omit(rowErrors, action.payload.field)
      return { ...state, [action.payload.data.id]: newRowErrors }
    }
    case FileImportActionTypes.RESET_ERROR_FOR_FILE:
    case FileImportActionTypes.REMOVE_FILE: {
      const newErrors = _.omit(state, action.payload.id)
      if (_.isEmpty(newErrors)) return initialState.errors
      return newErrors
    }
    case FileImportActionTypes.RESET_FILE_IMPORT:
      return initialState.errors
    default:
      return state
  }
}

export const fileImportReducer = combineReducers({
  files,
  journalDocumentSelection,
  errors
})

export const getFiles = (state: FileImportState) => state.files
export const getFilesById = (state: FileImportState) =>
  _.keyBy(state.files, "id")

export const getFileById = (state: FileImportState, id: string) => {
  for (let i = 0; i < state.files.length; i++) {
    const file = state.files[i]
    if (file.id == id) return file
    return null
  }
}

export const getJournalDocumentSelection = (state: FileImportState) =>
  state.journalDocumentSelection

export const getSelectedDocumentCountByJournalId = (state: FileImportState) => {
  const countbyJournalId = _.countBy(
    state.journalDocumentSelection,
    "journal.id"
  )
  return countbyJournalId
}
export const getFileImportErrors = (state: FileImportState) => state.errors
