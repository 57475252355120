const isMsie = ua => {
  const msie = ua.indexOf("MSIE ") //Interent explorer
  if (msie === -1) {
    return false
  }
  return !!parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
}

const isTrident = ua => {
  const trident = ua.indexOf("Trident/")
  if (trident === -1) {
    return false
  }
  const rv = ua.indexOf("rv:")
  return !!parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
}

export const browserIsIE = () => {
  return (
    isMsie(window.navigator.userAgent) || isTrident(window.navigator.userAgent)
  )
}
