import * as React from "react"
import { useState } from "react"
import {
  ChatArea,
  ChatField,
  QuestionInput,
  ResponseToUser
} from "@norkart/nora-components"
import { ToiBox, ToiStack, ToiTypography } from "@norkart/toi-components"
import { planprat } from "./types"
import { errorText, introText, introTitle } from "../../config/const"
import {
  cacheNewChatHistoryItem,
  getCachedChatHistory
} from "../../cache/chatHistory"
import ResetButton from "./ResetButton"
import { ToiThemeProvider } from "@norkart/toi-theme"
import { postChat } from "../../services/apiPlanprat"
import { formatMultiplePlans } from "../../utils/formatArealplan"
import { Arealplan } from "../../hooks/arealplaner/types"
import usePlanerForEiendom from "../../hooks/plananalyse/usePlanerForEiendom"
import { useSearchParams } from "react-router-dom"
import { useFilters } from "../../features/search/Search/filters"

interface ChatProps {
  plans: Arealplan[]
  kundeId: string
}

type responseObject = {
  answer: string
  content_from: string
}

const Chat = (props: ChatProps) => {
  const [exampleQuestion, setExampleQuestion] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [chatHistory, setChatHistory] = useState(getCachedChatHistory())
  //Consider removing activeSearchIndices and hard code as there is no selector in this component
  const [activeSearchIndices, setActiveSearchIndices] = useState<string[]>([])
  const filters = useFilters()
  const knr = filters.get("knr")
  const gnr = Number(filters.get("gnr"))
  const bnr = Number(filters.get("bnr"))
  const fnr = Number(filters.get("fnr"))
  const snr = Number(filters.get("snr"))
  const planerForEiendom = usePlanerForEiendom({ knr, gnr, bnr, fnr, snr })

  const updateHistory = (q: string, type?: "human" | "ai" | "system") => {
    const qObj: ResponseToUser = { content: q, type: type }
    cacheNewChatHistoryItem({ ...qObj, searchIndices: activeSearchIndices })
    setChatHistory(getCachedChatHistory)
  }

  const resetHistory = () => {
    setChatHistory([])
  }

  const generateAnswer = (content: responseObject[]) => {
    var answerString = ""
    content.forEach(obj => {
      const planName =
        props.plans.find(plan => {
          return plan.planId === obj.content_from
        })?.planNavn || "Planroboten"
      answerString =
        answerString + planName + " sier:\n\n" + obj.answer + "\n\n\n"
    })
    return answerString
  }

  const sendQuestion = async (message: string) => {
    setIsFetching(true)
    updateHistory(message, "human")
    const plans = formatMultiplePlans(props.plans)
    try {
      const res = await postChat({ message, chatHistory, plans }, props.kundeId)
      const answer = generateAnswer(res.data.content)
      updateHistory(answer, "ai")
      setIsFetching(false)
      return res.data.content.answer
    } catch (error) {
      updateHistory("Det skjedde en feil. Vennligst prøv igjen", "system")
      setIsFetching(false)
    }
  }

  return (
    <ToiThemeProvider>
      <ToiStack sx={{ margin: "auto" }}>
        <ChatArea>
          <ToiTypography mt={4} mb={2} variant="h2">
            Planprat - Spør planroboten om det du lurer på
          </ToiTypography>
          <ChatField
            botName="Planprat"
            hasError={false}
            selectedConversation={planprat}
            conversationConfig={[]}
            chatHistory={chatHistory}
            isFetching={isFetching}
            errorText={errorText}
            setExampleQuestion={setExampleQuestion}
          />
        </ChatArea>
        <ToiBox m={"auto"} width="80vw" maxWidth={"1100px"}>
          <QuestionInput
            disabled={false}
            clearOnSend={true}
            onSendChat={sendQuestion}
            exampleQuestion={exampleQuestion}
            setExampleQuestion={setExampleQuestion}
            isLoading={false}
            conversationType={planprat}
            onAbortMutation={function (): void {
              throw new Error("Function not implemented.")
            }}
          />
          <ResetButton resetChatHistory={resetHistory} />
        </ToiBox>
      </ToiStack>
    </ToiThemeProvider>
  )
}

export default Chat
