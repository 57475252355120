import * as React from "react"

import { Accordion, Box, Button, Grid, Paper } from "@mui/material"

import { ConnectedReduxProps, getSearchState } from "../../../store"
import DesktopContainer from "../../../containers/DesktopContainer"
import GjeldendeBestemmelser from "./Components/GjeldendeBestemmelser"
import LoadingSpinner from "../../../components/LoadingSpinner"
import MapIcon from "@mui/icons-material/Map"
import MobileContainer from "../../../containers/MobileContainer"
import PlanInfoDetaljer from "./Components/PlanInfoDetaljer"
import PlanInfoHeader from "./Components/PlanInfoHeader"
import PlanMap from "../../map/PlanMap"
import PlanNotifications from "../PlanNotifications"
import SearchIcon from "@mui/icons-material/Search"
import TopButtonsMenu from "./Components/TopButtonsMenu"
import { shouldShowExternalMapButton } from "./helpers"
import { showDialog } from "../../../store/dialog/actions"
import { toMoreMapFunctions } from "../../map/helpers/toMoreMapFunctions"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useBehandlinger from "../../../hooks/behandlinger/useBehandlinger"
import useCan from "../../../utils/auth/useCan"
import useMeta from "../../../hooks/meta/useMeta"
import useMidlertidigForbud from "../../../hooks/midlertidigForbud/useMidlertidigForbud"
import { useNavigate } from "react-router-dom"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import useSearch from "../../../hooks/search/useSearch"
import qs from "query-string"
import { useSelector } from "react-redux"

export interface PlanInfoProps extends ConnectedReduxProps {
  resetMap: () => void
  visDispIKart: boolean
}

const PlanInfo: React.FC<PlanInfoProps> = ({
  visDispIKart,
  ...props
}: PlanInfoProps) => {
  const can = useCan()
  const meta = useMeta()
  const navigate = useNavigate()
  const kundeId = useSelectedKundeId()
  const { data: plan, isLoading } = useArealplan()
  const { data: behandlinger } = useBehandlinger()
  const midlertidigForbud = useMidlertidigForbud(plan.id)
  const searchState = useSelector(getSearchState)

  const handleEditClick = () => {
    props.dispatch(
      showDialog({ dialogType: "EDIT_PLAN", dialogProps: { plan } })
    )
  }
  const handleSearch = () => {
    props.resetMap()
    navigate({
      pathname: `/${kundeId}/arealplaner/search`,
      search: qs.stringify({ ...searchState.urlSearchParams })
    })
  }

  const handleMapClick = () => {
    toMoreMapFunctions(plan, meta.visplanUrl)
  }

  const handleMapClickVisGI = () => {
    toMoreMapFunctions(plan, meta.visplanInternUrl)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }
  return (
    <>
      <MobileContainer>
        <Paper sx={theme => ({ padding: theme.spacing(8) })}>
          <Box
            sx={theme => ({
              [theme.breakpoints.down("md")]: {
                textAlign: "center",
                marginBottom: "10px"
              }
            })}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleSearch}
              sx={theme => ({
                marginRight: "4px",
                [theme.breakpoints.down("md")]: { width: "80%" }
              })}>
              <SearchIcon fontSize="small" sx={{ marginRight: "4px" }} />
              Til plansøk
            </Button>
          </Box>
          <PlanInfoHeader plan={plan} />
          <Box sx={{ marginBottom: "10px" }}>
            <PlanNotifications
              plan={plan}
              behandlinger={behandlinger}
              midlertidigForbud={midlertidigForbud}
              variant="blocks"
            />
          </Box>
          <Box
            sx={{
              height: "300px",
              marginBottom: "10px"
            }}>
            <PlanMap showDispInMap={visDispIKart} />
          </Box>
          <Box style={{ marginBottom: 15 }}>
            <GjeldendeBestemmelser />
          </Box>

          {shouldShowExternalMapButton(plan) && meta.visplanInternUrl && (
            <Grid
              container={true}
              justifyContent="space-around"
              sx={theme => ({
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                  marginBottom: "10px"
                }
              })}>
              {can.view && (
                <Grid item={true} xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleMapClickVisGI}
                    sx={theme => ({
                      marginRight: "4px",
                      [theme.breakpoints.down("md")]: { width: "80%" }
                    })}>
                    <MapIcon
                      fontSize="small"
                      sx={{ color: "green", marginRight: "4px" }}
                    />
                    {meta.visplanInternTekst || "VisGI"}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Paper>
        <Accordion
          expanded={false}
          title={`Planinformasjon`}
          style={{ padding: 10 }}>
          <PlanInfoDetaljer plan={plan} />
        </Accordion>
      </MobileContainer>
      <DesktopContainer>
        <TopButtonsMenu
          userCanView={can.view}
          userCanEdit={can.edit}
          handleSearch={handleSearch}
          handleMapClickVisGI={
            shouldShowExternalMapButton(plan) ? handleMapClickVisGI : undefined
          }
          handleMapClick={
            shouldShowExternalMapButton(plan) ? handleMapClick : undefined
          }
          handleEditClick={handleEditClick}
        />
        <PlanInfoHeader plan={plan} />
        <div
          style={{
            marginTop: 10,
            backgroundColor: "#eaeaea",
            maxWidth: "100%",
            padding: 20
          }}>
          <div>
            <PlanInfoDetaljer plan={plan} />
          </div>
        </div>
        <Box
          sx={theme => ({
            flexGrow: 1,
            fontWeight: 300,
            textTransform: "uppercase",
            [theme.breakpoints.down("md")]: { justifyContent: "flex-start" }
          })}>
          <GjeldendeBestemmelser />
        </Box>
        <PlanNotifications
          plan={plan}
          behandlinger={behandlinger}
          midlertidigForbud={midlertidigForbud}
          variant="blocks"
        />
      </DesktopContainer>
    </>
  )
}

export default PlanInfo
