const filetypes = [
  "pdf",
  "docx",
  "doc",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "sos",
  "sosi",
  "jfif",
  "tiff",
  "bpm",
  "txt"
]
export const getFileExtension = (filename: string, filetype?: string) => {
  let ext: string | undefined
  if (filename.search(/\./) != -1) ext = filename.split(".").pop()
  if (ext && filetypes.includes(ext.toLowerCase())) return ext

  if (!ext && filetype) {
    const ft = filetype.toLowerCase()
    // Handles RA-PDF filetypes from archive
    if (ft.search(/pdf/) != -1) return "pdf"
  }
  return ext
}
