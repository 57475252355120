import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"

type Props = TextFieldProps & {
  onClearInput: (event) => void
  loading?: boolean
  ariaLabel: string
  placeholder: string
}

export const TextFilter = ({
  onClearInput,
  loading,
  ariaLabel,
  ...props
}: Props) => {
  return (
    <TextField
      type="text"
      size="small"
      variant="outlined"
      sx={{ margin: 0 }}
      InputProps={{
        "aria-label": ariaLabel,
        endAdornment: (
          <InputAdornment position="end">
            <>
              {props.value && (
                <IconButton onClick={onClearInput} aria-label="Fjern søkeord">
                  <CloseIcon />
                </IconButton>
              )}
              {loading && (
                <IconButton>
                  <CircularProgress size={27.5} />
                </IconButton>
              )}
            </>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}
