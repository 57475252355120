import * as React from "react"
export interface ErrorProps {
  error: any
}

const Error: React.FC<ErrorProps> = ({ error }: ErrorProps) => {
  return <span style={{ color: "red" }}>{error}</span>
}

export default Error
