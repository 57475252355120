import { useFilters, FilterType } from "./useFilters"

export function useFilter(filterType: FilterType) {
  const filters = useFilters()

  const get = (): string | null => {
    return filters.get(filterType)
  }

  const set = (value: string) => {
    filters.set(filterType, value)
  }

  const clear = () => {
    filters.clear(filterType)
  }

  return { set, clear, get }
}
