import useMeta from "../../../hooks/meta/useMeta"
import { useMemo } from "react"
import { CheckboxSectionOption } from "./filters/CheckboxSection"

export default function useSearchHelpers() {
  const meta = useMeta()
  const capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const isvalidSaksnummer = (input?: string): boolean => {
    if (!input) return false

    const year = input.substring(0, 4)
    if (!isValidYear(parseInt(year))) {
      return false
    }
    let sekvensnummer
    if (input.split("/").length == 2) {
      sekvensnummer = input.split("/")[1]
    } else {
      sekvensnummer = input.substring(4, input.length)
    }
    if (!sekvensnummer || !isNumeric(sekvensnummer)) {
      return false
    }

    return true
  }
  const isNumeric = (value: string) => {
    return /^-{0,1}\d+$/.test(value)
  }

  const isValidYear = (year: number): boolean => {
    const current_year = new Date().getFullYear()
    return year > 1900 && year <= current_year
  }

  const removeLeadingZeros = (sekvensnummer: string) => {
    const num = parseInt(sekvensnummer, 10)
    return num.toString()
  }

  const planstatuserCheckboxList = useMemo<CheckboxSectionOption[]>(() => {
    return meta.data.planstatuser.map(elem => {
      return {
        value: elem.id.toString(),
        label: `Planstatus: ${capitalize(elem.beskrivelse)}`
      }
    })
  }, [meta.data.planstatuser])

  const plantyperCheckboxList = useMemo<CheckboxSectionOption[]>(() => {
    let sortedList: { value; label }[] = []
    const bringToFrontValues = ["30", "31", "34", "35"]
    sortedList = meta.data.plantyper.map(elem => {
      return {
        value: elem.id.toString(),
        label: `Plantype: ${capitalize(elem.beskrivelse)}`
      }
    })
    return bringElementsToFront(sortedList, bringToFrontValues)
  }, [meta.data.plantyper])

  const behandlingstyperCheckboxList = useMemo<CheckboxSectionOption[]>(() => {
    var result = getDistinctIds(
      meta.data.behandlingstyper,
      "behandlingstypeId",
      "behandlingstype"
    )
    const bringtofrontvalues = ["39", "31", "16", "4"]
    return bringElementsToFront(result, bringtofrontvalues)
  }, [meta.data.behandlingstyper])

  function getDistinctIds(list, idKey, labelName) {
    const result = [] as CheckboxSectionOption[] // List of unique ids
    const map = new Map()
    for (const item of list) {
      if (!map.has(item[idKey])) {
        map.set(item[idKey], true) // set any value to Map
        result.push({
          value: item[idKey].toString(),
          label: labelName + ": " + capitalize(item.beskrivelse)
        })
      }
    }
    return result
  }

  function bringElementsToFront(elementList, bringToFrontValues) {
    bringToFrontValues.forEach(toFrontValue => {
      if (elementList.find(item => item.value === toFrontValue)) {
        elementList = [
          elementList.find(item => item.value === toFrontValue)!,
          ...elementList.filter(item => item.value !== toFrontValue)
        ]
      }
    })
    return elementList
  }

  return {
    capitalize,
    isvalidSaksnummer,
    isNumeric,
    isValidYear,
    removeLeadingZeros,
    planstatuserCheckboxList,
    plantyperCheckboxList,
    behandlingstyperCheckboxList
  }
}
