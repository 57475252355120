import { useResolvedPath } from "react-router-dom"
import useMeta from "../../../hooks/meta/useMeta"
import Behandlinger from "./Behandlinger"
import Dispensasjoner from "./Dispensasjoner"
import Konsistenssjekk from "./Konsistenssjekk"
import Kostra from "./Kostra"
import Plan from "./Plan"
import Planforhold from "./Planforhold"
import Saksbehandlingstid from "./Saksbehandlingstid"
import Tilstand from "./Tilstand"
import useCan from "../../../utils/auth/useCan"

export default function useReportTypes() {
  const meta = useMeta()
  const url = useResolvedPath("").pathname
  const can = useCan()

  const reportTypes = [
    {
      Id: 1,
      Navn: "Konsistenssjekk",
      to: `${url}/konsistenssjekk`,
      path: `konsistenssjekk`,
      component: Konsistenssjekk,
      tilgang: meta.hasFeature("konsistenssjekk_tilgang") && can.edit
    },
    {
      Id: 2,
      Navn: "Kostra",
      to: `${url}/kostra`,
      path: `kostra`,
      component: Kostra,
      tilgang: false
    },
    {
      Id: 3,
      Navn: "Behandlinger",
      to: `${url}/behandlinger`,
      path: `behandlinger`,
      component: Behandlinger,
      tilgang: true
    },
    {
      Id: 4,
      Navn: "Dispensasjoner",
      to: `${url}/dispensasjoner`,
      path: `dispensasjoner`,
      component: Dispensasjoner,
      tilgang: true
    },
    {
      Id: 5,
      Navn: "Planforhold",
      to: `${url}/planforhold`,
      path: `planforhold`,
      component: Planforhold,
      tilgang: true
    },
    {
      Id: 5,
      Navn: "Planrapport",
      to: `${url}/planrapport`,
      path: `planrapport`,
      component: Plan,
      tilgang: true
    },
    {
      Id: 6,
      Navn: "Tilstandsrapport",
      to: `${url}/tilstandsrapport`,
      path: `tilstandsrapport`,
      component: Tilstand,
      tilgang: true
    },
    {
      Id: 7,
      Navn: "Saksbehandlingstid",
      to: `${url}/saksbehandlingstid`,
      path: `saksbehandlingstid`,
      component: Saksbehandlingstid,
      tilgang: true
    }
  ] as {
    Id: number
    Navn: string
    to: string
    path: string
    component: React.ComponentType<any>
    tilgang: boolean
  }[]

  return reportTypes
}
