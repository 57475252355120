import { Popup } from "@norkart/nkm-mapbox-map"

export const popupStyle = {
  "& .popup_container": {
    "& p": { fontSize: "16px" },
    "& button": {
      border: "none",
      padding: "10px",
      margin: "10px",
      cursor: "pointer",
      fontSize: "17px",
      color: "#484848",
      "&:hover": {
        color: "black",
        textDecoration: "underline"
      },
      "&:focus": {
        outline: "none"
      },
      div: { display: "flex", alignItems: "center" },
      svg: { width: "22px", height: "22px", margin: "4px" }
    }
  },
  "& .mapboxgl-popup-close-button": {
    fontSize: "20px",
    "&:focus": {
      outline: "none"
    }
  }
}

export const createMapDetailsPopup = (point, onClickCallback): Popup => {
  const container = document.createElement("div")
  container.className = "popup_container"
  const button = document.createElement("button")
  button.innerHTML = "Se kartdetaljer"
  button.onclick = onClickCallback
  container.appendChild(button)
  return { point: point, html: container }
}

export const createMoreFunctionsPopup = (point, onClickCallback): Popup => {
  const container = document.createElement("div")
  container.className = "popup_container"
  const button = document.createElement("button")
  button.innerHTML = "Se kart over hele området"
  button.onclick = onClickCallback
  container.appendChild(button)
  return { point: point, html: container }
}

export const createInfoAndButtonPopup = (
  point,
  text: string,
  onClickCallback
): Popup => {
  const container = document.createElement("div")
  container.className = "popup_container"
  container.id = "disp_popup"

  const textElement = document.createElement("p")
  textElement.innerHTML = text
  container.appendChild(textElement)

  const button = document.createElement("button")
  button.innerHTML =
    '<div><svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg><span>Endre dispensasjon</span></div>'
  button.onclick = onClickCallback
  button.className = ""
  container.appendChild(button)

  return { point: point, html: container }
}

export const createInfoPopup = (point, text: string) => {
  const container = document.createElement("div")
  container.className = "popup_container"
  const textElement = document.createElement("p")
  textElement.innerHTML = text
  container.appendChild(textElement)
  return { point: point, html: container }
}
