import { fetchArealplanIdByPlanId } from "../../../../../../../services/api"

export const getArealplanId = async (
  planId: string,
  administrativEnhet?: string
) => {
  if (administrativEnhet === undefined) {
    console.log("ingen administrativ enhet angitt")
    return
  }
  const res = await fetchArealplanIdByPlanId({ planId, administrativEnhet })
  if (res) {
    return { id: res, planId: planId }
  } else {
    console.log("ingen resultat i planregister for id: " + planId)
  }
}
