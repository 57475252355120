import React from "react"
import { Box, Typography } from "@mui/material"
import { useState } from "react"
import FilterLabel from "./FilterLabel"
import { TextFilter } from "./TextFilter"
import { useFilter } from "./useFilter"
import useSearchHelpers from "../useSearchHelpers"

export const SaksnummerFilter = () => {
  const [error, setError] = useState<string | undefined>()
  const filter = useFilter("saksnummer")
  const filterValue = filter.get()
  const helpers = useSearchHelpers()

  const formatSaksnummer = (value?: string): string => {
    //Help user format saksnummer correctly
    if (!value) {
      return ""
    }
    // Fjern alt som ikke er tall
    let cleanValue = value.replace(/[^0-9]*$/g, "")

    // Legg på slash igjen om man prøver å skrive det på rikig sted
    if (value.length === 5 && value.indexOf("/") === 4) cleanValue += "/"

    // Formatering hvis man har både år og saksnummer
    if (cleanValue.length > 4) {
      let updatedValue = value
      const year = cleanValue.substring(0, 4)

      if (cleanValue.split("/").length === 2) {
        const sekvensnummer = cleanValue.split("/")[1]
        if (sekvensnummer.length) {
          updatedValue = year + "/" + helpers.removeLeadingZeros(sekvensnummer)
        }
      } else {
        const sekvensnummer = cleanValue.substring(4, cleanValue.length)
        updatedValue = year + "/" + helpers.removeLeadingZeros(sekvensnummer)
      }
      return updatedValue
    }
    return cleanValue
  }

  const handleChange = e => {
    const value = e.target.value
    const cleanValue = formatSaksnummer(value)
    filter.set(cleanValue)

    const isValid = isValidSaksnummer(cleanValue)

    if (isValid) {
      setError(undefined)
    }

    if (!isValid && value) {
      setError(
        "Ugyldig saksnummer, må ha format saksår/sekvensnummer. Eks: 2019/12345"
      )
    }

    if (value === "") {
      setError(undefined)
      filter.clear()
    }
  }

  const isValidSaksnummer = (value?: string): boolean => {
    return helpers.isvalidSaksnummer(value)
  }
  // TODO: formatering av saksnummer
  return (
    <>
      <FilterLabel>Angi saksnummer (saksår/sekvensnummer)</FilterLabel>
      <Box sx={{ display: "flex" }}>
        <TextFilter
          onClearInput={() => {
            filter.clear()
          }}
          value={filterValue || ""}
          onChange={handleChange}
          placeholder="Eks: 2016/12345"
          ariaLabel="Søk på saksnummer"
        />
      </Box>
      {error && filterValue && (
        <div>
          <Typography
            sx={{
              color: "red",
              fontStyle: "italic"
            }}>
            {error}
          </Typography>
        </div>
      )}
    </>
  )
}
