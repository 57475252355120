import NkmNorkartSearch from "nkm-norkart-search"
import React from "react"
import { Box } from "@mui/material"
import config from "../../../../../config"
import { subSearch } from "nkm-norkart-search/dist/utils/subSearchFunctions"
import useMeta from "../../../../../hooks/meta/useMeta"
import "nkm-norkart-search/dist/utils/style.css"

type Props = {
  initialValue?: string
  onPropertySearch: (event: any) => void
  onClearPropertyResult: () => void
}

export const PropertySearchInput = (props: Props) => {
  const meta = useMeta()
  return (
    <Box
      sx={theme => ({
        "& .nk-search": { height: "100%" },
        "& input": {
          padding: "20px",
          lineHeight: "20px",
          width: "100%",
          fontSize: "1.1428571428571428rem",
          fontWeight: 300
        },
        "& .result-list": {
          position: "absolute"
        },
        "& .input-container": {
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          border: "1px solid grey!important",
          "&:focus": {
            border: `1px solid ${theme.palette.secondary.main}!important`
          }
        },
        "& .search-input-icons": {
          "& #searchIcon": {
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)"
            },
            "& svg": {
              opacity: 0.6
            }
          }
        },
        "& #eraseBtn": {
          "& svg": {
            visibility: "visible",
            opacity: 0.6
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
          }
        }
      })}>
      <NkmNorkartSearch
        autofocus={false}
        placeholder={"Søk adresse eller gnr/bnr.."}
        searchIconColor={"black"}
        apiKey={config.planregister.token}
        targets={["matrikkelenhet", "gate", "gateadresse"]}
        numResults={10}
        initialValue={props.initialValue}
        subSearch={subSearch}
        limits={[meta.komnr]}
        hitSelected={props.onPropertySearch}
        whenClearingResults={props.onClearPropertyResult}
      />
    </Box>
  )
}
