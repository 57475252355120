import { Button, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material"
import * as React from "react"
import {
  lastDayOfMonth,
  subMonths,
  subYears,
  addYears,
  lastDayOfYear,
  startOfYear,
  format,
  startOfMonth,
  addMonths
} from "date-fns"
import { nb } from "date-fns/locale"
import { StartOrEndDate } from "./DateRangePickerMui"
import {AppCalendarIcon} from '@norkart/toi-icons';
import { theme } from "../../../features/NyhetsTjeneste/Nyheter"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

type DateRangePickerFlexDatesProps = {
  intervalSelected: (d: StartOrEndDate) => void
  show?: "months" | "years" | "all"
}

export function DateRangePickerFlexDates({
  intervalSelected,
  show
}: DateRangePickerFlexDatesProps) {
  const [years, setYears] = React.useState<Date[]>()
  const [months, setMonths] = React.useState<Date[]>()
  const [toggleValue, setToggleValue] = React.useState<string | null>(
    show === "months" ? "month" : "year"
  )

  React.useEffect(() => {
    handleMonthIntervals()
    handleYearIntervals()
  }, [])

  React.useMemo(() => {
    if (toggleValue === "month") {
    }
    if (toggleValue === "year") {
    }
  }, [toggleValue])

  const getMonthInterval = (month: Date) => {
    const firstDay = startOfMonth(month)
    const lastDay = lastDayOfMonth(month)

    return intervalSelected({ start: firstDay, end: lastDay } as StartOrEndDate)
  }

  const getYearInterval = (date: Date) => {
    const firstDay = startOfYear(date)
    const lastDay = lastDayOfYear(date)

    return intervalSelected({ start: firstDay, end: lastDay } as StartOrEndDate)
  }

  const getYTD = e => {
    const lastDay = new Date()
    const calculateFirstDay = subYears(new Date(), 1)
    const firstDay = new Date(calculateFirstDay)

    return intervalSelected({ start: firstDay, end: lastDay } as StartOrEndDate)
  }

  const handleToggle = (event: React.SyntheticEvent, newValue: string) => {
    setToggleValue(newValue)
  }

  const handleYearIntervals = (
    nextInterval: boolean = false,
    previousInterval: boolean = false
  ) => {
    const numberOfYearsCount = 10
    let selectedYears: Date[] = []

    if (previousInterval && years) {
      let selectedYears: Date[] = []
      const firstOfCurrentInterval = years[0]
      for (let i = 1; i <= numberOfYearsCount; i++) {
        const year = subYears(firstOfCurrentInterval, i)
        selectedYears.push(year)
      }
      setYears(selectedYears.reverse())
      return
    }

    if (nextInterval && years) {
      let selectedYears: Date[] = []
      const lastOfCurrentInterval = years[years.length - 1]
      for (let i = 1; i <= numberOfYearsCount; i++) {
        const year = addYears(lastOfCurrentInterval, i)
        selectedYears.push(year)
      }
      setYears(selectedYears)
      return
    }

    const today = new Date()

    for (let i = 0; i <= 9; i++) {
      const year = subYears(today, i)
      selectedYears.push(year)
    }
    setYears(selectedYears.reverse())
  }

  const handleMonthIntervals = (
    nextInterval: boolean = false,
    previousInterval: boolean = false
  ) => {
    const numberOfMonthsCount = 10
    let selectedMonths: Date[] = []

    if (previousInterval && months) {
      let selectedMonths: Date[] = []
      const firstOfCurrentInterval = months[0]
      for (let i = 1; i <= numberOfMonthsCount; i++) {
        const month = subMonths(firstOfCurrentInterval, i)
        selectedMonths.push(month)
      }
      setMonths(selectedMonths.reverse())
      return
    }

    if (nextInterval && months) {
      let selectedMonths: Date[] = []
      const lastOfCurrentInterval = months[months.length - 1]
      for (let i = 1; i <= numberOfMonthsCount; i++) {
        const year = addMonths(lastOfCurrentInterval, i)
        selectedMonths.push(year)
      }
      setMonths(selectedMonths)
      return
    }

    const today = new Date()

    for (let i = 0; i <= 9; i++) {
      const month = subMonths(today, i)
      selectedMonths.push(month)
    }
    setMonths(selectedMonths.reverse())
  }

  return (
    <Grid sx={{ width: "calc(100%)" }} container={true} justifyContent="center">
      <Grid item={true} sx={{ marginBottom: 30, marginTop: 5 }}>
        <ToggleButtonGroup value={toggleValue}>
          {(!show || show === "years" || show === "all") && (
            <ToggleButton
              sx={{
                width: 80,
                borderRadius: 0
              }}
              color="secondary"
              value="year"
              onClick={handleToggle}>
              År
            </ToggleButton>
          )}
          {(!show || show === "months" || show === "all") && (
            <ToggleButton
              sx={{
                width: 80,
                borderRadius: 0
              }}
              color="secondary"
              value="month"
              onClick={handleToggle}>
              Måned
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Grid>
      <Grid
        justifyContent={"center"}
        sx={{ display: "flex", flexWrap: "wrap" }}
        container={true}>
        {toggleValue === "month" ? (
          <Grid container={true} direction="row">
            <Grid
              justifyContent={"center"}
              sx={{ display: "inherit", flexWrap: "inherit" }}
              item={true}>
              <Button
                onClick={() => handleMonthIntervals(false, true)}
                sx={{
                  alignSelf: "center",
                  "&.MuiButton-root": {
                    padding: "0px 0px 0px 0px",
                    minHeight: "0px",
                    minWidth: "0px",
                    width: 20,
                    height: 190,
                    overflow: "visible"
                  },
                  ":hover": {
                    backgroundColor: "ø"
                  },
                  justifySelf: "flex-start",
                  display: "flex"
                }}
                disableRipple={true}
                disableFocusRipple={true}>
                <ArrowBackIosNewIcon />
              </Button>
            </Grid>
            <Grid
              justifyContent="center"
              sx={{
                justifyContent: "center",
                display: "flex",
                flex: 1,
                flexWrap: "inherit",
                width: "fit-content"
              }}
              item={true}>
              {months?.map((m, i) => (
                <Button
                  sx={{
                    alignContent: "center",
                    width: 110,
                    height: 89,
                    margin: 1,
                    flexDirection: "column"
                  }}
                  onClick={() => getMonthInterval(m)}
                  color="secondary"
                  variant="outlined"
                  key={i + "selectMonth"}>
                  <AppCalendarIcon />
                  <div
                    style={{ marginTop: 2, color: theme.palette.text.primary }}>
                    {format(m, "LLLL", { locale: nb }).toUpperCase()}
                  </div>
                  <div
                    style={{
                      marginTop: -2,
                      fontSize: 14,
                      color: theme.palette.text.primary
                    }}>
                    {m.getFullYear()}
                  </div>
                </Button>
              ))}
            </Grid>
            <Grid
              justifyContent={"center"}
              sx={{ display: "inherit", flexWrap: "inherit" }}
              item={true}>
              <Button
                onClick={() => handleMonthIntervals(true, false)}
                sx={{
                  "&.MuiButton-root": {
                    padding: "0px 0px 0px 0px",
                    minHeight: "0px",
                    minWidth: "0px",
                    width: 20,
                    height: 200
                  },
                  justifySelf: "flex-start",
                  display: "flex"
                }}
                disableRipple={true}
                disableFocusRipple={true}>
                <ArrowForwardIosIcon />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container={true} direction="row">
            <Grid
              justifyContent={"center"}
              sx={{ display: "inherit", flexWrap: "inherit" }}
              item={true}>
              <Button
                onClick={() => handleYearIntervals(false, true)}
                sx={{
                  alignSelf: "center",
                  "&.MuiButton-root": {
                    padding: "0px 0px 0px 0px",
                    minHeight: "0px",
                    minWidth: "0px",
                    width: 20,
                    height: 190,
                    overflow: "visible"
                  },
                  ":hover": {
                    backgroundColor: "ø"
                  },
                  justifySelf: "flex-start",
                  display: "flex"
                }}
                disableRipple={true}
                disableFocusRipple={true}>
                <ArrowBackIosNewIcon />
              </Button>
            </Grid>
            <Grid
              sx={{
                justifyContent: "center",
                display: "flex",
                flex: 1,
                flexWrap: "inherit",
                width: "fit-content"
              }}
              item={true}>
              {years?.map((y, i) => (
                <Button
                  sx={{
                    alignContent: "center",
                    width: 110,
                    height: 89,
                    margin: 1,
                    flexDirection: "column"
                  }}
                  onClick={() => getYearInterval(y)}
                  color="secondary"
                  variant="outlined"
                  key={i + "selectMonth"}>
                  <AppCalendarIcon />
                  <div
                    style={{
                      paddingTop: 15,
                      paddingBottom: 10,
                      color: theme.palette.text.primary
                    }}>
                    {y.getFullYear()}
                  </div>
                </Button>
              ))}
            </Grid>
            <Grid
              justifyContent={"center"}
              sx={{ display: "inherit", flexWrap: "inherit" }}
              item={true}>
              <Button
                onClick={() => handleYearIntervals(true, false)}
                sx={{
                  "&.MuiButton-root": {
                    padding: "0px 0px 0px 0px",
                    minHeight: "0px",
                    minWidth: "0px",
                    width: 20,
                    height: 200
                  },
                  justifySelf: "flex-start",
                  display: "flex"
                }}
                disableRipple={true}
                disableFocusRipple={true}>
                <ArrowForwardIosIcon />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
