import bboxPolygon from "@turf/bbox-polygon"

export const mapConstants = {
  defaultBeforeLayerId: "n50-sportidrettsplass", //nederste lag som wms'er skal tegnes under
  layerIdPrefix: "plan-wms-",
  MFT_layer_name: "MFT",
  aboveDifflayerId: "n50-sportidrettsplass" // første lag som kommer over diffLayer
}

export const boundingBoxNorway = bboxPolygon([2, 56, 33, 73])

export const regelsettConst = {
  MidlertidigForbudMotTiltak: "MidlertidigForbudMotTiltak",
  MidlertidigByggOgAnleggsomraade: "MidlertidigByggOgAnleggsomraade",
  Kommuneplan: "Kommuneplan",
  Kommunedelplan: "Kommunedelplan",
  Planforslag: "Planforslag",
  PlanforslagIVannsoylen: "PlanforslagIVannsoylen",
  PlanforslagOverGrunnen: "PlanforslagOverGrunnen",
  PlanforslagPaaBunnen: "PlanforslagPaaBunnen",
  PlanforslagUnderGrunnen: "PlanforslagUnderGrunnen",
  PlanleggingIgangsatt: "PlanleggingIgangsatt",
  PlanleggingIgangsattOverGrunnen: "PlanleggingIgangsattOverGrunnen",
  PlanleggingIgangsattUnderGrunnen: "PlanleggingIgangsattUnderGrunnen",
  RegPaaGrunnen: "RegPaaGrunnen",
  RegOverGrunnen: "RegOverGrunnen",
  RegUnderGrunnen: "RegUnderGrunnen",
  RegPaaBunnen: "RegPaaBunnen",
  RegIVannsoylen: "RegIVannsoylen",
  Bebyggelsesplan: "Bebyggelsesplan",
  BebyggelsesplanOverGrunnen: "BebyggelsesplanOverGrunnen",
  BebyggelsesplanUnderGrunnen: "BebyggelsesplanUnderGrunnen",
  KommuneplanUnderArbeid: "KommuneplanUnderArbeid",
  KommunedelplanUnderArbeid: "KommunedelplanUnderArbeid"
}

export const vertikalMapping = {
  1: [regelsettConst.RegUnderGrunnen], // Under grunnen (tunnel),
  2: [regelsettConst.RegPaaGrunnen], //"På grunnen/vannoverflaten"
  3: [regelsettConst.RegOverGrunnen], //"Over grunnen (bru)"
  4: [regelsettConst.RegPaaBunnen], //På bunnen (vann/sjø),
  5: [regelsettConst.RegIVannsoylen] //I vannsøylen
}
