import {ConversationType} from '@norkart/nora-components';

export const planprat: ConversationType = {
    id: 'planprat',
    searchIndices: [],
    name: 'Planprat',
    description: 'Planprat - Søker i plandokumenter',
    sampleQuestions: [
        'Hva er oppgitt utnyttelsesgrad?',
        'Er det krav om utomhusplan?',
        'Hvor høyt kan jeg bygge på tomten min?',
        'Hva betyr gesimshøyde?',
    ],
    loadingText: 'Planroboten søker i plandokumentene, vent litt...',
    placeholder: 'Spør planroboten!',
    logo: '',
};
