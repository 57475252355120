
import * as React from 'react'
import { useMemo } from "react"
import { InfiniteData, useInfiniteQuery } from "react-query"
import { DispensasjonerQueryVariables } from '../../features/reports/Reports/Dispensasjoner/Dispensasjoner'
import { GraphQLPagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"
import { DispensasjonerReport } from "./types"

const MapPagedDispensasjoner = (data: InfiniteData<GraphQLPagedResponse>) => {
  const mappedData = data?.pages.flatMap(b =>  {
    if(b.dispensasjonerPaged?.edges)
      return b.dispensasjonerPaged.edges.map(e => e.node)
    else return undefined
  }) as DispensasjonerReport[] 
  return mappedData 
} 

export default function useDispensasjonerGraphQL(graphqlQuery: string, variables:DispensasjonerQueryVariables) {
  
  const query = useInfiniteQuery<GraphQLPagedResponse>(
    ["dispensasjonerReportGraphQL", variables],
    ({pageParam=variables}) => graphQLRequest(graphqlQuery, pageParam),
    {
        getNextPageParam: response => {
          if(response?.dispensasjonerPaged.pageInfo?.hasNextPage) {
            const test = {...variables, after: response.dispensasjonerPaged.pageInfo.endCursor}
            return test
          } else return undefined
          },
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)

    const dispensasjoner: DispensasjonerReport[] = useMemo(() => {
      if(query.data) return MapPagedDispensasjoner(query.data) 
      else return [] as DispensasjonerReport[]
      }, [query.data?.pages])
    
      return { ...query,dispensasjoner: dispensasjoner|| [], totalCount: query.data ? query.data.pages[0]?.dispensasjonerPaged.totalCount : undefined }

  }