import * as React from "react"
import { Sak } from "../../../hooks/arealplaner/types"
import { formatSaksnr } from "../../../utils/formatSaksnr"
import theme from "../../../theme"
import useCan from "../../../utils/auth/useCan"

export interface SakItemProps {
  sak: Partial<Sak>
}

const SakItem: React.FC<SakItemProps> = ({ sak }: SakItemProps) => {
  const can = useCan()
  var url = can.view ? sak.urlIntern : sak.url
  if (url)
    return (
      <a
        href={`${url}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.secondary.dark }}>
        {formatSaksnr(sak.sakAar, sak.sakSeknr)}
      </a>
    )
  return <span>{formatSaksnr(sak.sakAar, sak.sakSeknr)}</span>
}

export default SakItem
