import { ApplicationState, createRootReducer } from "./store"
import { configureStore } from "@reduxjs/toolkit";
import { AuthActionTypes } from "./store/auth/types";

export default function getStore(
  initialState: ApplicationState
) {
  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: initialState as any,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [AuthActionTypes.SET_AUTH_IS_LOADING, AuthActionTypes.SET_AUTH_STATE],
          // Ignore these field paths in all actions
          ignoredActionPaths: ['payload.getStringSetting','payload.getBooleanSetting'],
          // Ignore these paths in the state
          ignoredPaths: [
            'auth.authorization.getStringSetting', 
            'auth.authorization.getBooleanSetting', 
            'auth.authorization.getNumberSetting'],
        },
      }),
  });
  return store
}
