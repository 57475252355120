declare global {
  interface Window {
    config: any
  }
}

window.config = window.config || {}

interface PlanregisterConfig {
  token: string
  url: string
  customer: string
  profile: string
  client_id: string
  environment: string
  authority: string
  api_scope: string
}

interface MatrikkelkartConfig {
  token: string
  url: string
}

interface Config {
  planregister: PlanregisterConfig
  matrikkelkart: MatrikkelkartConfig
  mapbox: {
    accessToken: string
    styleUrl: string
  }
  tjenestepanel: {
    appid: string
  }
  kommuneinfo: {
    url: string
  }
  applicationInsightsKey: string
  applicationInsightsConnectionString: string
  mittinnspill: {
    apiUrl: string
    url: string
  }
}

export const getSetting: any = (key: string) => {
  return (window.config && window.config[key]) || process.env[key]
}

const config: Config = {
  planregister: {
    token: getSetting("REACT_APP_API_KEY") || "",
    url: getSetting("REACT_APP_API_BASE_URL") || "",
    customer: getSetting("REACT_APP_API_CUSTOMER") || "",
    profile: getSetting("REACT_APP_WAAPI_PROFILE") || "",
    client_id: getSetting("REACT_APP_CLIENT_ID") || "",
    environment: getSetting("REACT_APP_HOSTING_ENVIRONMENT") || "",
    authority: getSetting("REACT_APP_MSAL_AUTHORITY") || "",
    api_scope: getSetting("REACT_APP_API_SCOPE") || ""
  },
  matrikkelkart: {
    url: getSetting("REACT_APP_MATRIKKELKART_URL") || "",
    token: getSetting("REACT_APP_API_KEY") || ""
  },
  mapbox: {
    accessToken: getSetting("REACT_APP_MAP_ACCESSTOKEN"),
    styleUrl: getSetting("REACT_APP_MAPBOX_STYLE")
  },
  kommuneinfo: {
    url: getSetting("REACT_APP_KOMMUNEINFO")
  },
  tjenestepanel: {
    appid: getSetting("REACT_APP_TJENESTEPANEL_APPID")
  },
  applicationInsightsKey: getSetting("REACT_APP_APPLICATION_INSIGHTS_KEY"),
  applicationInsightsConnectionString: getSetting(
    "REACT_APP_APPLICATION_INSIGHTS_CS"
  ),
  mittinnspill: {
    apiUrl: getSetting("REACT_APP_MITTINNSPILL_API_BASE_URL"),
    url: getSetting("REACT_APP_MITTINNSPILL_BASE_URL")
  }
}

export default config
