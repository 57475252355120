import * as React from "react"
import { Box, Tab, Tabs } from "@mui/material"
import _ from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import LoadingSpinner from "../../../components/LoadingSpinner"
import useTrackArealplanerEvent from "../../../hooks/appinsights/useTrackArealplanerEvent"
import useMeta from "../../../hooks/meta/useMeta"

import { getSearchState } from "../../../store"
import { addActiveSearchTab } from "../../../store/search/actions"
import { SearchTabType } from "../../../store/search/types"
import "./search.css"
import ActiveFiltersList from "./ActiveFiltersList"
import AdvancedFilters from "./AdvancedFilters"
import {
  PropertyGeometryMissing,
  PropertySearchFilter,
  SearchTermFilter
} from "./filters"

export type SearchTab = {
  label: string
  type: SearchTabType
}

function Search() {
  const dispatch = useDispatch()
  const searchState = useSelector(getSearchState)
  const location = useLocation()
  const meta = useMeta()

  const trackSearch = useTrackArealplanerEvent(
    "forside",
    "button",
    undefined,
    true
  )

  const searchTabs = [
    {
      label: "Eiendom/Adresse",
      type: "property"
    },
    {
      label: "Plan-navn/id",
      type: "name_id"
    }
  ] as SearchTab[]

  const handleSearchTypeChange = (event, value) => {
    dispatch(addActiveSearchTab(value, searchTabs[value].type))
  }

  if (meta.isLoading) {
    return <LoadingSpinner />
  }
  return (
    <Box
      sx={theme => ({
        width: "100%",
        maxWidth: "700px",
        paddingTop: "10px",
        margin: "0 auto",
        ".Mui-focused": {
          "& fieldset": {
            border: `1px solid ${theme.palette.secondary.main}!important`
          }
        }
      })}>
      <Tabs
        variant="fullWidth"
        value={searchState.activeSearchTab}
        onChange={handleSearchTypeChange}
        sx={theme => ({
          backgroundColor: "transparent",
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.secondary.main
          }
        })}>
        {searchTabs.map(tab => (
          <Tab
            key={tab.label}
            label={tab.label}
            onClick={() => trackSearch(`Tab_${tab.label}`)}
          />
        ))}
      </Tabs>
      {searchState.activeSearchFilter === "name_id" && <SearchTermFilter />}
      {searchState.activeSearchFilter === "property" && (
        <PropertySearchFilter />
      )}
      <AdvancedFilters />
      <ActiveFiltersList />
      <PropertyGeometryMissing />
    </Box>
  )
}

export default Search
