import { useMutation, useQueryClient } from "react-query"
import { updateInnstilling } from "../../services/api"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

export default function useUpdateInnstillinger() {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  return useMutation(updateInnstilling, {
    onSuccess: () => {
      queryClient.invalidateQueries(["meta", { kundeId }])
    }
  })
}
