import { useMemo } from "react"
import { useInfiniteQuery } from "react-query"
import { useSelector } from "react-redux"
import useSearchHelpers from "../../features/search/Search/useSearchHelpers"
import { searchArealplan } from "../../services/api"
import {
  getActiveSortFilter,
  getSearchParams,
  getSearchState
} from "../../store"
import { searchFiltersToQueryStringToApi } from "../../utils/searchFiltersToQueryString"
import { Arealplan } from "../arealplaner/types"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import useMeta from "../meta/useMeta"
import useDebounce from "../useDebounce"

type SearchResult = {
  data: Arealplan[]
  pagination: Pagination
  pageParam: number
}

type Pagination = {
  TotalCount: number
  PageSize: number
  CurrentPage: number
  TotalPages: number
  HasNext: boolean
  HasPrevious: boolean
}

const getSearchResults = async (
  searchParams,
  activeSortFilter,
  page
): Promise<SearchResult> => {
  const filters = {
    ...searchParams,
    ...activeSortFilter,
    page
  }
  const res = await searchArealplan(searchFiltersToQueryStringToApi(filters))
  const pagination = JSON.parse(res.headers["x-pagination"]) as Pagination
  return {
    data: res.data,
    pagination,
    pageParam: filters.page
  }
}

export default function useSearch() {
  const meta = useMeta()
  const activeSortFilter = useSelector(getActiveSortFilter)
  const kundeId = useSelectedKundeId()
  const searchParams = useSelector(getSearchParams)
  const helpers = useSearchHelpers()
  const searchState = useSelector(getSearchState)
  const debouncedSearchParams = useDebounce(searchParams, 500)

  const isValidSearchParams = searchParams?.saksnummer
    ? helpers.isvalidSaksnummer(searchParams?.saksnummer)
    : true

  const query = useInfiniteQuery<SearchResult>(
    [
      "search",
      { kundeId, searchParams: debouncedSearchParams, activeSortFilter }
    ],
    ({ pageParam = 1 }) =>
      getSearchResults(debouncedSearchParams, activeSortFilter, pageParam),
    {
      getNextPageParam: lastPage => {
        if (lastPage?.pagination?.HasNext) {
          return lastPage.pageParam + 1
        } else return undefined
      },
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 1000 * 60 * 5, // 5m
      enabled: !!kundeId && meta.isFetched && isValidSearchParams
    }
  )
  const mapResults = useMemo(() => {
    let data = [] as Arealplan[]
    if (query?.data?.pages) {
      query?.data?.pages.map(page => {
        for (const res in page.data) {
          data.push(page.data[res])
        }
      })
    }
    return data
  }, [query.data])

  return {
    ...query,
    searchState,
    results: mapResults,
    totalCount: query?.data?.pages[0]?.pagination?.TotalCount,
    activeSortFilter
  }
}
