import * as React from "react"
import WarningIcon from "@mui/icons-material/WarningOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { Collapse, Button, Typography, Box } from "@mui/material"
import { amber } from "@mui/material/colors"

export interface PlanNotificationProps {
  title: string | React.ReactElement
  description?: string | React.ReactElement
}

const PlanNotification: React.FC<PlanNotificationProps> = ({
  title,
  description
}: PlanNotificationProps) => {
  const [expanded, setExpanded] = React.useState(false)

  function renderTitle() {
    return (
      <Box sx={{ display: "flex", width: "100%", padding: "10px" }}>
        <WarningIcon
          fontSize="small"
          sx={{ marginRight: "8px", display: "flex", color: amber[700] }}
        />
        {typeof title === "string" ? (
          <Typography
            sx={{ textAlign: "left", display: "flex", fontWeight: 500 }}>
            {title}
          </Typography>
        ) : (
          <>{title}</>
        )}
      </Box>
    )
  }

  return (
    <Box sx={{ border: `2px solid ${amber[700]}`, margin: "10px 0" }}>
      {description ? (
        <Button
          variant="text"
          onClick={() => setExpanded(!expanded)}
          sx={{ width: "100%", padding: "0" }}>
          {renderTitle()}
          {expanded ? (
            <ExpandLessIcon sx={{ marginRight: "6px" }} />
          ) : (
            <ExpandMoreIcon sx={{ marginRight: "6px" }} />
          )}
        </Button>
      ) : (
        renderTitle()
      )}
      <Collapse in={expanded}>
        <Box sx={{ padding: "5px" }}>{description}</Box>
      </Collapse>
    </Box>
  )
}

export default PlanNotification
