import React from "react"
import HelpButton from "../../../../components/HelpButton/HelpButton"
import { helpObject } from "../../../../help/helpObject"
import CheckboxSectionAlt from "./CheckboxSection"
import useSearchHelpers from "../useSearchHelpers"
import { useFilter } from "./useFilter"
import FilterLabel from "./FilterLabel"

export const PlanStatusFilter = () => {
  const helpers = useSearchHelpers()
  const filter = useFilter("planstatus")

  const getSelectedFilters = (): string[] => {
    return filter.get()?.split(",") || []
  }

  const handleChange = (value: string) => {
    filter.set(value)
  }

  return (
    <>
      <FilterLabel>
        Velg planstatus
        <HelpButton
          helpText={helpObject.AvansertFilter.Planstatus}
          type="PlanstatusFilter"
        />
      </FilterLabel>
      <CheckboxSectionAlt
        selectedOptions={getSelectedFilters()}
        options={helpers.planstatuserCheckboxList}
        handleChange={handleChange}
      />
    </>
  )
}
