import { Arealplan } from "../hooks/arealplaner/types";

export type PlanpratPlan = {
    plan_id: string,
    metadata: string,
    knr: string,
    plantype: string
}

export const formatArealplan = (plan: Arealplan) => {
    const newPlan = {plan_id: plan.planId, metadata: "", knr: plan.komnr.toString(), plantype: plan.planType}
    return newPlan as PlanpratPlan;
}

export const formatMultiplePlans = (plans: Arealplan[]) => {
    const newPlans: PlanpratPlan[] = []
    plans.forEach(p => {
        const newPlan = formatArealplan(p);
        newPlans.push(newPlan);
    });

    return newPlans
}