import { AvailableWmsLayer } from "../types"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { AlwaysHideKey } from "../Components/InfoDialog/MapInformationDialog"
import { KartlagKonfig, LagKategorier } from "../../../hooks/meta/types"
import { regelsettConst as rs } from "./constants"

/* ALL RULES ARE DEFINED BASED ON INFO FROM KJERSTI HOV */

//Strings in kartlagInnstillinger verdi refers to name on wms layers and are defined in tjenestekatalogen

/**Hvilke kartlag som skal være tilgjengelig gitt en spesifikk kombinasjon av plantype og planstatus */
export const filterAvailableLayersForPlan = (
  availableWmsLayers: AvailableWmsLayer[],
  planstatus: number,
  plantype: number,
  vertikalnivaa: number[],
  planHasMidlertidigForbud: boolean
): AvailableWmsLayer[] => {
  //Finn kartlag som skal være tilgjengelige på denne planen
  const filteredLayers: AvailableWmsLayer[] = []

  availableWmsLayers.forEach(layer => {
    let shouldLayerBeAvailable = false

    if (layer.ruleset.includes(rs.MidlertidigForbudMotTiltak)) {
      if (planHasMidlertidigForbud) {
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.Kommuneplan)) {
      if (plantype === 20 && (planstatus === 3 || planstatus === 8)) {
        // Hvis plantype er "Kommuneplanens arealdel"
        // og planstatus er "Endelig vedtatt arealplan" eller "Overstyrt"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.Kommunedelplan)) {
      if (plantype === 21 && (planstatus === 3 || planstatus === 8)) {
        // Hvis plantype er "Kommunedelplan"
        // og planstatus er "Endelig vedtatt arealplan" eller "Overstyrt"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.KommuneplanUnderArbeid)) {
      if (
        (plantype === 20 || plantype === 22) &&
        (planstatus === 1 || planstatus === 2)
      ) {
        // Hvis plantype er "Kommuneplanens arealdel" eller "Mindre endring av kommune(del)plan"
        // og planstatus er "Planlegging igangsatt" eller "Planforslag"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.KommunedelplanUnderArbeid)) {
      if (plantype === 21 && (planstatus === 1 || planstatus === 2)) {
        // Hvis plantype er "Kommunedelplan"
        // og planstatus er "Planlegging igangsatt" eller "Planforslag"
        shouldLayerBeAvailable = true
      }
    }

    //----------Vertikalnivå relaterte regler--------------

    if (layer.ruleset.includes(rs.RegOverGrunnen)) {
      if (
        vertikalnivaa.includes(3) &&
        !(
          plantype === 20 ||
          plantype === 21 ||
          plantype === 22 ||
          plantype === 32 ||
          plantype === 33
        ) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Over grunnen" er et relevant vertikalnivå for denne planen
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.RegPaaGrunnen)) {
      if (
        vertikalnivaa.includes(2) &&
        !(
          plantype === 20 ||
          plantype === 21 ||
          plantype === 22 ||
          plantype === 32 ||
          plantype === 33
        ) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "På grunnen" er et relevant vertikalnivå for denne planen
        // og plantype IKKE er "Kommuneplanens arealdel", "Kommunedelplan" eller "Mindre endring av kommune(del)plan"
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.RegUnderGrunnen)) {
      if (
        vertikalnivaa.includes(1) &&
        !(
          plantype === 20 ||
          plantype === 21 ||
          plantype === 22 ||
          plantype === 32 ||
          plantype === 33
        ) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Under grunnen" er et relevant vertikalnivå for denne planen
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.RegPaaBunnen)) {
      if (
        vertikalnivaa.includes(4) &&
        !(
          plantype === 20 ||
          plantype === 21 ||
          plantype === 22 ||
          plantype === 32 ||
          plantype === 33
        ) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "På bunnen" er et relevant vertikalnivå for denne planen
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.RegIVannsoylen)) {
      if (
        vertikalnivaa.includes(5) &&
        !(
          plantype === 20 ||
          plantype === 21 ||
          plantype === 22 ||
          plantype === 32 ||
          plantype === 33
        ) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "I vannsøylen" er et relevant vertikalnivå for denne planen
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.Bebyggelsesplan)) {
      if (
        vertikalnivaa.includes(2) &&
        (plantype === 32 || plantype === 33) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "På grunnen" er et relevant vertikalnivå for denne planen
        // og plantype er "Bebyggelsesplan iht. reguleringsplan (PBL 1985)" eller "Bebyggelsesplan iht. kommuneplanens arealdel (PBL 1985)"
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }
    if (layer.ruleset.includes(rs.BebyggelsesplanOverGrunnen)) {
      if (
        vertikalnivaa.includes(1) &&
        (plantype === 32 || plantype === 33) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Under grunnen" er et relevant vertikalnivå for denne planen
        // og plantype er "Bebyggelsesplan iht. reguleringsplan (PBL 1985)" eller "Bebyggelsesplan iht. kommuneplanens arealdel (PBL 1985)"
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.BebyggelsesplanOverGrunnen)) {
      if (
        vertikalnivaa.includes(3) &&
        (plantype === 32 || plantype === 33) &&
        !(planstatus === 1 || planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Over grunnen" er et relevant vertikalnivå for denne planen
        // og plantype er "Bebyggelsesplan iht. reguleringsplan (PBL 1985)" eller "Bebyggelsesplan iht. kommuneplanens arealdel (PBL 1985)"
        // og planstatus IKKE er "Planlegging igangsatt", "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }

    //----------Vertikalnivå under arbeid relaterte regler---------

    if (layer.ruleset.includes(rs.PlanforslagUnderGrunnen)) {
      if (
        vertikalnivaa.includes(1) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        (planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Under grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planforslag"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.Planforslag)) {
      if (
        vertikalnivaa.includes(2) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        (planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "På grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planforslag"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.PlanforslagOverGrunnen)) {
      if (
        vertikalnivaa.includes(3) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        (planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "Over grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planforslag"
        shouldLayerBeAvailable = true
      }
    }

    if (layer.ruleset.includes(rs.PlanforslagPaaBunnen)) {
      if (
        vertikalnivaa.includes(4) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        (planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "På bunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planforslag"
        shouldLayerBeAvailable = true
      }
    }
    if (layer.ruleset.includes(rs.PlanforslagIVannsoylen)) {
      if (
        vertikalnivaa.includes(5) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        (planstatus === 2 || planstatus === 6)
      ) {
        // Hvis "I vannsøylen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planforslag" eller "Vedtatt plan med utsatt rettsvirkning"
        shouldLayerBeAvailable = true
      }
    }
    if (layer.ruleset.includes(rs.PlanleggingIgangsatt)) {
      if (
        vertikalnivaa.includes(2) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        planstatus === 1
      ) {
        // Hvis "På grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planlegging igangsatt"
        shouldLayerBeAvailable = true
      }
    }
    if (layer.ruleset.includes(rs.PlanleggingIgangsattOverGrunnen)) {
      if (
        vertikalnivaa.includes(3) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        planstatus === 1
      ) {
        // Hvis "Over grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planlegging igangsatt"
        shouldLayerBeAvailable = true
      }
    }
    if (layer.ruleset.includes(rs.PlanleggingIgangsattUnderGrunnen)) {
      if (
        vertikalnivaa.includes(1) &&
        (plantype === 30 ||
          plantype === 31 ||
          plantype === 34 ||
          plantype === 35) &&
        planstatus === 1
      ) {
        // Hvis "Under grunnen" er et relevant vertikalnivå under arbeid for denne planen
        // og plantype er "Eldre reguleringsplan (PBL 1985)", "Mindre reguleringsendring", "Detaljregulering" eller "Områderegulering"
        // og planstatus er "Planlegging igangsatt"
      }
    }

    if (shouldLayerBeAvailable) {
      filteredLayers.push(layer)
    }
  })

  return filteredLayers
}

export const shouldDisplayMapInfoDialog = (plan: Arealplan) => {
  const dontShowInfoDialog = localStorage.getItem(AlwaysHideKey)
  return (
    plan &&
    plan.vertikalniva &&
    plan.vertikalniva.length > 0 &&
    plan.planStatusId === 3 && //May have to change this because some planer with status 1 or 2 in other municipalities may have vertniv
    dontShowInfoDialog !== "true"
  )
}
