import { ApplicationState } from "."
import * as fromFileImport from "./fileImport/reducer"
import * as fromKunder from "./kunder/reducer"
import * as fromSearch from "./search/reducer"
import * as fromShared from "./shared/reducer"
import * as fromSession from "./session/reducer"
import * as fromAuth from "./auth/reducer"

export const getSearchState = (state: ApplicationState) => state.search
export const getMapState = (state: ApplicationState) => state.map

// Search selectors
export const getActiveSortFilter = (state: ApplicationState) =>
  fromSearch.getActiveSortFilter(getSearchState(state))

export const searchIsActive = (state: ApplicationState) =>
  fromSearch.searchIsActive(getSearchState(state))
export const getSearchParams = (state: ApplicationState) =>
  fromSearch.getUrlSearchParams(getSearchState(state))

// Kunde selectors
const getKunderState = (state: ApplicationState) => state.kunder
export const getSelectedCustomer = (state: ApplicationState) =>
  fromKunder.getSelectedCustomer(getKunderState(state))

// Shared state selector
const getSharedState = (state: ApplicationState) => state.shared
export const getGeneralErrors = (state: ApplicationState) =>
  fromShared.getGeneralErrors(getSharedState(state))

// File import
export const getFileImportState = (state: ApplicationState) => state.fileImport
export const getFiles = (state: ApplicationState) =>
  fromFileImport.getFiles(getFileImportState(state))
export const getFilesById = (state: ApplicationState) =>
  fromFileImport.getFilesById(getFileImportState(state))
export const getFileById = (state: ApplicationState, id: string) =>
  fromFileImport.getFileById(getFileImportState(state), id)
export const getJournalDocumentSelection = (state: ApplicationState) =>
  fromFileImport.getJournalDocumentSelection(getFileImportState(state))
export const getSelectedDocumentCountByJournalId = (state: ApplicationState) =>
  fromFileImport.getSelectedDocumentCountByJournalId(getFileImportState(state))
export const getFileImportErrors = (state: ApplicationState) =>
  fromFileImport.getFileImportErrors(getFileImportState(state))

// Session
const getSessionState = (state: ApplicationState) => state.session
export const getActivePlanTab = (state: ApplicationState) =>
  fromSession.getActivePlanTab(getSessionState(state))
export const getActiveSakId = (state: ApplicationState) =>
  fromSession.getActiveSakId(getSessionState(state))
export const getUseAltFrontpage = (state: ApplicationState) =>
  fromSession.getUseAltFrontpage(getSessionState(state))

export const getExpandDispensasjonId = (state: ApplicationState) =>
  fromSession.getExpandDispensasjonId(getSessionState(state))

export const getExpandBehandlingId = (state: ApplicationState) =>
  fromSession.getExpandBehandlingId(getSessionState(state))

// Auth
const getAuthState = (state: ApplicationState) => state.auth
export const getAuthorization = (state: ApplicationState) =>
  fromAuth.getAuthorization(getAuthState(state))
export const getAuthorizationIsLoading = (state: ApplicationState) =>
  fromAuth.getAuthorizationIsLoading(getAuthState(state))
