import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import axios, { AxiosPromise } from "axios"
import _ from "lodash-es"
import * as React from "react"
import { useEffect } from "react"
import { useQueryClient } from "react-query"
import { connect, ConnectedProps } from "react-redux"
import { Dispatch } from "redux"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import { FILE_SIZE_LIMIT } from "../../../config/const"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { Behandling } from "../../../hooks/behandlinger/types"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import useArealplanDokumenter from "../../../hooks/dokumenter/useArealplanDokumenter"
import {
  createArealplanDokument,
  createBehandlingDokument,
  createDispensasjonDokument
} from "../../../services/api"
import { ApplicationState, getFileImportErrors, getFiles } from "../../../store"
import { hideDialog } from "../../../store/dialog/actions"
import {
  addFile,
  reset,
  updateErrors,
  updateFile
} from "../../../store/fileImport/actions"
import { FileData, FileImportErrors } from "../../../store/fileImport/types"
import { getFileExtension } from "../../../utils/getFileExtension"
import { parseDate } from "../../../utils/parseDate"
import useDocumentHelpers from "../useDocumentHelpers"
import ImportDocumentList from "./ImportDocumentList"
import { isValidDate } from "../../../utils/isValidDate"

export type ImportDocumentDialogProps = {
  open: boolean
  behandling?: Behandling
  dispensasjon?: Dispensasjon
  plan: Arealplan
  files?: File[]
  showRowDetails: (id: String) => void
}

type Props = ConnectedProps<typeof connector> & ImportDocumentDialogProps

function ImportDocumentDialog({
  dispatch,
  open,
  files,
  behandling,
  dispensasjon,
  plan,
  fileData,
  errors,
  ...props
}: Props) {
  const { plandokumenter, gjeldendeBestemmelser } = useArealplanDokumenter()
  const [isUploading, setIsUploading] = React.useState(false)
  const queryClient = useQueryClient()
  const documentHelpers = useDocumentHelpers()
  // Reset when closed
  useEffect(() => {
    if (!open) {
      dispatch(reset())
    }
  }, [open])
  // Update list of files and errors when files or plan change
  // Warn if filesize is too large
  useEffect(() => {
    if (files) {
      const fileData = mapFiles()
      fileData.map(file => {
        dispatch(addFile(file))
      })
      checkFilesize(files)
    }
  }, [files])

  const getExistingDocumentNames = () => {
    return behandling
      ? behandling.dokumenter.map(doc => doc.dokumentnavn)
      : dispensasjon
      ? dispensasjon.dokumenter.map(doc => doc.dokumentnavn)
      : plandokumenter.map(doc => doc.dokumentnavn)
  }

  const fileExists = (file: File): boolean => {
    return getExistingDocumentNames().includes(file.name)
  }

  const mapFiles = () => {
    let fileData = [] as FileData[]
    files &&
      files
        .filter(file => file.size <= FILE_SIZE_LIMIT)
        .map((file, index) => {
          fileData.push({
            id: file.name,
            selected: true,
            dokumentNavn: file.name,
            dokumentTypeId: 0,
            dokumentDato: new Date(file.lastModified),
            horingsdokument: false,
            bestemmelser: false,
            plandokument: false,
            url: URL.createObjectURL(file),
            filetype: file.type,
            overwrite: false,
            tilgangId: documentHelpers.getDefaultTilgangId(behandling),
            exists: fileExists(file)
          })
        })
    return fileData
  }

  // Check and warn if any files are larger than file size limit
  function checkFilesize(files: File[]) {
    let tooBigFiles = [] as string[]
    files.map(file => {
      if (file.size > FILE_SIZE_LIMIT) {
        tooBigFiles.push(file.name)
      }
      if (tooBigFiles.length > 0)
        openSnackbarMessage({
          message: `${tooBigFiles.join(
            ", "
          )} er over 200 MB og kan ikke importeres.`,
          variant: "warning"
        })
    })
  }

  // Maps FileData to a form and submits one file
  async function uploadFile(fileData: FileData) {
    const formData = new FormData()
    behandling && formData.append("BehandlingId", behandling.id.toString())
    dispensasjon &&
      formData.append("DispensasjonId", dispensasjon.id.toString())
    if (fileData.dokumentDato) {
      formData.append(
        "Dokumentdato",
        parseDate(fileData.dokumentDato.toISOString())!
      )
    }
    if (fileData.dokumentTypeId != 0)
      formData.append("DokumenttypeId", fileData.dokumentTypeId.toString())
    formData.append("Horingsdokument", fileData.horingsdokument.toString())
    formData.append("VisIPlandokumenter", fileData.plandokument.toString())
    formData.append(
      "VisIGjeldendeBestemmelser",
      fileData.bestemmelser.toString()
    )
    formData.append("Dokumentnavn", fileData.dokumentNavn)
    formData.append("Overskriv", fileData.overwrite.toString())
    formData.append("TilgangId", fileData.tilgangId.toString())
    formData.append("Beskrivelse", fileData.beskrivelse || "")
    if (fileData.url) {
      const res = await fetch(fileData.url)
      const blob = await res.blob()
      const file = new File([blob], fileData.dokumentNavn, {
        type: fileData.filetype
      })
      formData.append("file", file)
    }

    // Sending behandlingid separately because of lack of FormData.get in IE
    return behandling
      ? createBehandlingDokument(formData, behandling.id)
      : dispensasjon
      ? createDispensasjonDokument(formData, dispensasjon.id)
      : createArealplanDokument(formData, plan.id)
  }

  function handleClose() {
    hideDialog(dispatch).then(() => {
      setIsUploading(false)
    })
  }

  const validate = (data: FileData[]): FileImportErrors => {
    let _errors = {} as FileImportErrors

    data.forEach(async (file, index) => {
      if (!file.dokumentTypeId) {
        _.set(_errors, [file.id, "dokumentTypeId"], "Dokumenttype er påkrevd")
      }
      // Sjekke filnavn fra skjema mot eksisterende filnavn
      if (
        getExistingDocumentNames().includes(file.dokumentNavn) &&
        !file.overwrite
      ) {
        props.updateFile({ ...file, exists: true })
        _.set(
          _errors,
          [file.id, "dokumentNavn"],
          "Dokumentet finnes fra før og vil bli overskrevet"
        )
      }

      // Ikke tillat å importere flere filer med likt navn
      if (
        data
          .filter(d => d.id != file.id)
          .map(d => d.dokumentNavn)
          .includes(file.dokumentNavn)
      ) {
        _.set(_errors, [file.id, "dokumentNavn"], "Dokumenter må ha unike navn")
      }

      // Ikke tillatt å importere filer uten filnavn
      if (file.dokumentNavn === "." + getFileExtension(file.dokumentNavn)) {
        _.set(_errors, [file.id, "dokumentNavn"], "Dokumentet må ha et navn")
      }

      // Validering av dato
      // TODO: Tror ikke denne trigges pga. sjekk før update. Får trøbbel med konvertering av ugyldig dato hvis det går gjennom.
      // if (!isValidDate(file.dokumentDato)) {
      //   _.set(_errors, [file.id, "dokumentDato"], "Ugyldig dato")
      // }
    })
    return _errors
  }

  // Uploads all files and expand details of behandling after completion
  function handleSubmit() {
    // Filter which files should be sent
    let fileDataFiltered = fileData.filter(
      file => (file.exists && file.overwrite === true) || !file.exists
    )

    let hasBestemmelser = false
    fileDataFiltered.forEach(async (file, index) => {
      if (file.dokumentTypeId == 5 && file.bestemmelser) hasBestemmelser = true
    })

    // Check if form table contains any errors
    let _errors = validate(fileDataFiltered)

    if (Object.keys(_errors).length === 0) {
      // Notify if multiple Gjeldende bestemmelser
      if (hasBestemmelser && gjeldendeBestemmelser.length > 0) {
        openSnackbarMessage({
          message: "Det ligger flere dokumenter under Gjeldende bestemmelser",
          variant: "warning"
        })
      }
      // If not, upload all documents
      let reqs = [] as AxiosPromise[]
      fileDataFiltered.forEach(file => {
        reqs.push(uploadFile(file))
      })
      setIsUploading(true)
      axios
        .all(reqs)
        .catch(err =>
          openSnackbarMessage({
            message:
              "Noe gikk galt under opplastingen. Vennligst prøv igjen senere.",
            variant: "error"
          })
        )
        .finally(() => {
          fileData.map(file => {
            file.url && URL.revokeObjectURL(file.url)
          })
          queryClient.invalidateQueries([
            "behandlinger",
            { arealplanId: plan.id }
          ])
          queryClient.invalidateQueries([
            "dispensasjoner",
            { arealplanId: plan.id }
          ])
          queryClient.invalidateQueries([
            "dokumenter",
            { arealplanId: plan.id }
          ])
          queryClient.invalidateQueries(["arealplan", { arealplanId: plan.id }])

          handleClose()
          behandling && props.showRowDetails(String(behandling.id))
          dispensasjon && props.showRowDetails(String(dispensasjon.id))
        })
    } else {
      props.updateErrors(_errors)
    }
  }

  const renderDialogContent = () => (
    <DialogContent>
      {isUploading ? (
        <LoadingSpinner text="Laster opp dokumenter..." />
      ) : (
        <ImportDocumentList
          documents={fileData}
          behandling={behandling}
          dispensasjon={dispensasjon}
        />
      )}
    </DialogContent>
  )

  const renderDialogActions = () => (
    <DialogActions>
      {!isUploading ? (
        <>
          <Button onClick={handleClose} variant="contained" color="grey">
            Avbryt
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            Last opp
          </Button>
        </>
      ) : null}
    </DialogActions>
  )

  return (
    <Dialog
      open={open}
      aria-labelledby="import-document-title"
      maxWidth="xl"
      fullWidth={true}
      disableScrollLock={true}
      scroll="paper"
      PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle id="import-document-title">Last opp dokument</DialogTitle>
      {renderDialogContent()}
      {renderDialogActions()}
    </Dialog>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  fileData: getFiles(state),
  errors: getFileImportErrors(state)
})
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateErrors: (errors: FileImportErrors) => dispatch(updateErrors(errors)),
  updateFile: (data: FileData) => dispatch(updateFile(data)),
  dispatch
})
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ImportDocumentDialog)
