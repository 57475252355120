
import * as React from 'react'
import { useQuery } from "react-query"
import {  GraphQLUnpagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"
import { PlanstatusLovreferanseReport } from './types'

export default function usePlanstatusLovreferaseGraphQL(graphqlQuery: string) {
  
  const query = useQuery<GraphQLUnpagedResponse>(
    ["planstatusLovreferanseReportGraphQL"],
    () => graphQLRequest(graphqlQuery),
    {
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)

    return { ...query,tilstandLovref: query.data?.planstatusLovreferanseReport as PlanstatusLovreferanseReport[] || []}
  }