import proj4 from "proj4"

export const getProj4Defs = () => {
  return [
    [
      //Lat lng
      "EPSG:4326",
      "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees"
    ],
    //UTM 32
    [
      "22",
      "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    ],
    //UTM 33
    [
      "23",
      "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
      //"+proj=utm +zone=33 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs"
    ],
    //UTM 35
    [
      "25",
      "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    ]
  ]
}

export const getProj4Def = (def?: string) => {
  if (def === "22") return "UTM sone 32"
  if (def === "23") return "UTM sone 33"
  if (def === "25") return "UTM sone 35"
  return "UTM sone 32"
}

export const toLatLng = (
  coords: { x: number; y: number },
  fromDatumCode
): { lat: number; lng: number } => {
  proj4.defs(getProj4Defs())
  const projectedCoords: {
    x: number
    y: number
  } = proj4(fromDatumCode, "EPSG:4326", { y: coords.x, x: coords.y }) //Proj4 uses x and y opposite
  return { lat: projectedCoords.y, lng: projectedCoords.x }
}
