import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography
} from "@mui/material"
import * as React from "react"

interface LinearProgressExtendedProps {
  value: number
}

const LinearProgressWithLabel: React.FC<LinearProgressExtendedProps> = ({
  value,
  ...props
}: LinearProgressProps) => {
  return (
    <Box
      display="flex"
      style={{ width: 1000, marginBottom: 10 }}
      alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={value}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="primary">{`${Math.round(
          value ? value : 0
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default LinearProgressWithLabel
