// Table structure based on material-table
import * as React from "react"
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Tooltip,
  Theme
} from "@mui/material"
import Icon, { IconProps } from "@mui/material/Icon"
import { styled } from "@mui/material/styles"

export interface GenericTableProps {
  title?: string
  columns: GTableColumn[]
  data: any[]
  idColumn: string
  options?: GTableOptions
  onRowClick?: (event, rowData, toggleDetailPanel) => void
  detailPanel?: DetailPanel[]
  actions?: GTableAction[]
  style?: React.CSSProperties
  components?: GTableComponents
  localization?: GTableLocalization
}

export interface GenericTableState {
  expandedRows: string[]
  columns: GTableColumn[]
  initialized: boolean
}

export interface GTableComponents {
  Header?: any
  colgroup?: any
}

export interface GTableLocalization {
  body: {
    emptyDataSourceMessage: string
  }
}

export interface GTableColumn {
  title: string | React.ReactNode
  tooltip?: string
  field?: string
  cellStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  render?: (rowdata) => any
}

export interface GTableAction {
  disabled?: boolean
  icon: string | (() => React.ReactElement<any>)
  tooltip?: string
  onClick: (event: any, data: any) => void
  iconProps?: IconProps
  hidden?: boolean | ((rowData) => boolean)
}

export interface GTableOptions {
  header?: boolean
  expanded?: boolean
  allowMultipleExpanded?: boolean
  actions?: boolean
}

export interface DetailPanel {
  render: (rowData) => any
  tooltip: string
}

export interface GTRef {
  focusRow: (id) => void
  expandWithId: (id) => void
}

export interface CustomIconProps extends IconProps {
  expanded?: boolean
}

const StyledIconButton = styled(IconButton)<CustomIconProps>(
  ({ theme, expanded }) => ({
    transition: "all ease 200ms",
    transform: expanded ? "rotate(90deg)" : "none"
  })
)

const RotatableIcon = styled(Icon)<CustomIconProps>(({ theme, expanded }) => ({
  transition: "transform 200ms ease",
  transform: expanded ? "rotate(90deg)" : "rotate(0deg)"
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  padding: "4px 8px 4px 4px",
  wordBreak: "keep-all"
}))

const DataRow = styled(TableRow)(({ theme }) => ({
  height: 65,
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  "&:hover": { backgroundColor: "#f0f0f0" }
}))

const HeaderRow = styled(TableRow)({
  borderTop: "none"
})

class GenericTable extends React.Component<
  GenericTableProps,
  GenericTableState
> {
  state = {
    columns: this.props.columns,
    expandedRows: [] as string[],
    initialized: false
  }
  static defaultProps = {
    localization: { body: { emptyDataSourceMessage: "No data" } },
    idColumn: "id",
    options: {
      header: true,
      expanded: false,
      allowMultipleExpanded: false,
      actions: true
    } as GTableOptions
  } as GenericTableProps

  defaultComponents = {
    Header: TableHead,
    colgroup: undefined
  } as GTableComponents

  getComponents = () => ({
    ...this.defaultComponents,
    ...this.props.components
  })

  UNSAFE_componentWillMount() {
    const { actions, detailPanel, columns } = this.props
    let cols = Array.from(columns)
    if (actions && actions.length > 0 && !this.state.initialized)
      // add column for actions if defined
      cols.push({ title: "", render: this.renderActions })
    if (detailPanel && detailPanel.length > 0 && !this.state.initialized) {
      // Add column for detail action if detail panel is defined
      cols.unshift({ title: "", render: this.renderDetailPanelActions })

      // Expand all rows if expanded is set
      if (this.props.options && this.props.options.expanded)
        this.setState({
          expandedRows: this.props.data.map(row => row[this.props.idColumn])
        })
    }
    this.setState({ columns: cols })
  }

  componentDidUpdate(prevProps: GenericTableProps) {
    if (this.props.options && this.props.options.expanded) {
      this.expandNewData(prevProps)
    }
  }

  expandNewData(prevProps: GenericTableProps) {
    var currentIds = this.props.data.map(d => d[this.props.idColumn])
    var prevIds = prevProps.data.map(d => d[this.props.idColumn])
    var newIds = currentIds.filter(id => !prevIds.includes(id))
    newIds.forEach(newId => {
      this.setState(prevState => {
        return { expandedRows: prevState.expandedRows.concat(newId) }
      })
    })
  }

  isExpanded = rowData =>
    this.state.expandedRows.indexOf(String(rowData[this.props.idColumn])) != -1

  toggleExpand = rowData => {
    this.isExpanded(rowData)
      ? this.setState({
          expandedRows: this.state.expandedRows.filter(
            expandedRow => expandedRow != rowData[this.props.idColumn]
          )
        })
      : this.setState({
          expandedRows:
            this.props.options && this.props.options.allowMultipleExpanded
              ? this.state.expandedRows.concat(
                  String(rowData[this.props.idColumn])
                )
              : [String(rowData[this.props.idColumn])]
        })
  }

  expandWithId = (id: string) => {
    if (this.state.expandedRows.indexOf(id) == -1) {
      this.setState({
        expandedRows:
          this.props.options && this.props.options.allowMultipleExpanded
            ? this.state.expandedRows.concat(id)
            : [id]
      })
    }
  }

  focusRow = (id: string) => {
    this.expandWithId(id)
    const el = document.getElementById(`action-btn-0-${id}`)
    if (el) el.focus()
  }

  handleActionHiding = (rowData, action) => {
    if (action.hidden) {
      if (typeof action.hidden === "function") return action.hidden(rowData)
      if (typeof action.hidden === "boolean") return action.hidden
    } else return false
  }

  renderAction = (rowData, action, index) => {
    if (!this.handleActionHiding(rowData, action)) {
      return (
        <Tooltip title={action.tooltip} key={index}>
          <StyledIconButton
            id={`action-btn-${index}-${rowData[this.props.idColumn]}`}
            expanded={this.isExpanded(rowData) ? true : undefined}
            onClick={e => {
              action.onClick(e, rowData)
              e.stopPropagation()
            }}>
            <action.icon {...action.iconProps} />
          </StyledIconButton>
        </Tooltip>
      )
    }
  }

  renderActions = rowData => {
    return this.props.actions && this.props.options?.actions ? (
      <div style={{ width: "100%", textAlign: "right" }}>
        {this.props.actions.map((action, index) =>
          this.renderAction(rowData, action, index)
        )}
      </div>
    ) : null
  }

  rotateIconStyle = isOpen => ({
    transform: isOpen ? "rotate(90deg)" : "none"
  })

  renderDetailPanelActions = rowData => {
    return this.props.detailPanel
      ? this.props.detailPanel.map((dp, index) => (
          <Tooltip title={dp.tooltip} key={index}>
            <StyledIconButton
              onClick={e => {
                this.toggleExpand(rowData)
                e.stopPropagation()
              }}
              expanded={this.isExpanded(rowData)}>
              <RotatableIcon expanded={this.isExpanded(rowData)}>
                chevron_right
              </RotatableIcon>
            </StyledIconButton>
          </Tooltip>
        ))
      : null
  }

  renderHeader = () => {
    const components = this.getComponents()
    return (
      <components.Header>
        <HeaderRow>
          {this.state.columns.map((column, index) => (
            <StyledTableCell
              variant="head"
              style={column.headerStyle}
              key={index + "header"}
              title={column.tooltip}>
              {column.title}
            </StyledTableCell>
          ))}
        </HeaderRow>
      </components.Header>
    )
  }

  renderDetailPanel = rowData => {
    return this.props.detailPanel
      ? this.props.detailPanel.map((dp, index) => (
          <DataRow key={`${rowData[this.props.idColumn]}-detail-${index}`}>
            <StyledTableCell colSpan={this.state.columns.length}>
              {dp.render(rowData)}
            </StyledTableCell>
          </DataRow>
        ))
      : null
  }

  renderRow = rowData => {
    return (
      <React.Fragment key={rowData[this.props.idColumn] + "rowdata"}>
        <DataRow
          onClick={e => {
            this.props.onRowClick &&
              this.props.onRowClick(e, rowData, () =>
                this.toggleExpand(rowData)
              )
          }}>
          {this.state.columns.map((column, index) => (
            <StyledTableCell style={column.cellStyle} key={index + "tablecell"}>
              {column.render
                ? column.render(rowData)
                : column.field
                ? rowData[column.field]
                : null}
            </StyledTableCell>
          ))}
        </DataRow>
        {this.isExpanded(rowData) && this.renderDetailPanel(rowData)}
      </React.Fragment>
    )
  }

  renderRows = () => {
    const { data } = this.props
    return (
      <TableBody key="tablebody">
        {data && data.length > 0 ? (
          data.map(rowData => this.renderRow(rowData))
        ) : (
          <DataRow key="empty">
            <StyledTableCell
              colSpan={this.state.columns.length}
              style={{
                textAlign: "center",
                padding: "12px",
                wordBreak: "keep-all"
              }}>
              {this.props.localization?.body.emptyDataSourceMessage}
            </StyledTableCell>
          </DataRow>
        )}
      </TableBody>
    )
  }

  render() {
    return (
      <Table style={{ ...this.props.style }} padding="none">
        {this.props.options?.header && this.renderHeader()}
        {this.renderRows()}
      </Table>
    )
  }
}

export default GenericTable
