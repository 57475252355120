import { Typography } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import { report } from "process"
import * as React from "react"
import { PlanstatusLovreferanseReport } from "../../../../hooks/customReports/types"
import { Lovreferanse } from "../../../../hooks/meta/types"
import { PlanforholdReport } from "../../../../hooks/planforhold/types"

export default function TilstandLovreferanseColumns() {
  return React.useMemo<ColumnDef<PlanstatusLovreferanseReport>[]>(
    () => [
      {
        id: "lovreferanse",
        cell: r => (
          <Typography component={"span"} fontSize={"0.85rem"} fontWeight={500}>
            {r.row.original.lovreferanse.beskrivelse}
          </Typography>
        ),
        accessorFn: r => r.lovreferanse.beskrivelse,
        header: () => "Lovreferanse",
        enableSorting: true,
        size: 300
      },
      {
        id: "gjeldendePlaner",
        accessorFn: r => r.gjeldende,
        header: () => "Gjeldende planer",
        enableSorting: true,
        size: 50
      },
      {
        id: "planerUnderArbeid",
        accessorFn: r => r.underArbeid,
        header: () => "Planer under arbeid",
        enableSorting: true,
        size: 50
      },
      {
        id: "ubestemt",
        accessorFn: r => r.ubestemt,
        header: () => "Ubestemt",
        enableSorting: true,
        size: 50
      },
      {
        id: "utgatt",
        accessorFn: r => r.utgatt,
        header: () => "Utgått",
        enableSorting: true,
        size: 50
      }
    ],
    []
  )
}
