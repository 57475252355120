import { styled } from "@mui/material"
import MuiTextFieldSearch from "../../../../../components/ReportComponents/MuiTextFieldSearch"

export const StyledMuiTextFieldSearch = styled(MuiTextFieldSearch)(
  ({ theme }) => ({
    "& .MuiInputLabel-root": {
      position: "relative",
      color: theme.palette.primary.main,
      transformOrigin: "unset",
      transform: "unset",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "uppercase",
      "&.Mui-focused": { color: "black!important" }
    },
    "&:hover fieldset": {
      outline: `2px solid ${theme.palette.primary.main}`
    },
    "& .Mui-focused fieldset": {
      outline: `1px solid ${theme.palette.secondary.main}`
    },
    "& fieldset": {
      outline: `1px solid ${theme.palette.primary.main}`,
      border: "none",
      marginTop: 5
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white"
    }
  })
)
