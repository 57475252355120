import * as React from "react"
import { UrlSearchParams } from "../store/search/types"
import qs from "qs"
import { useNavigate, useSearchParams } from "react-router-dom"
import useSelectedKundeId from "./kunder/useSelectedKundeId"
import { ArealplanId } from "./arealplaner/types"

export const useNavigateToArealplan = () => {
  const navigate = useNavigate()
  const selectedKundeId = useSelectedKundeId()
  const [searchParams] = useSearchParams()

  const urlSearchParams = qs.parse(searchParams.toString(), {
    ignoreQueryPrefix: true
  }) as Partial<UrlSearchParams>

  const toArealplan = (arealplanId: ArealplanId) => {
    navigate({
      pathname: `/${selectedKundeId}/arealplaner/${arealplanId}`,
      search: qs.stringify(urlSearchParams)
    })
  }

  const toArealplanFullscreen = (arealplanId: ArealplanId) => {
    navigate({
      pathname: `/${selectedKundeId}/arealplaner/${arealplanId}/fullskjerm`,
      search: qs.stringify(urlSearchParams)
    })
  }

  return { toArealplan, toArealplanFullscreen }
}
