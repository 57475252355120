import { Link } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import * as React from "react"
import useMeta from "../../../../hooks/meta/useMeta"
import { format } from "date-fns"
import { BehandlingReportResponse } from "../../../../hooks/behandlinger/useBehandlingerReport"
import useSelectedKundeId from "../../../../hooks/kunder/useSelectedKundeId"

const baseUrl = window.location.origin

export default function BehandlingerColumns() {
  const kundeId = useSelectedKundeId()
  return React.useMemo<ColumnDef<BehandlingReportResponse>[]>(
    () => [
      {
        id: "planId",
        header: () => "PlanId",
        cell: r => (
          <Link
            key={r.row.id + "link"}
            href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.arealplan.planId}&kommunenummer=${r.row.original?.arealplan.komnr}`}
            target="_blank"
            variant="body1"
            underline="none"
            color="secondary"
            fontSize="0.85rem">
            {r.row.original?.arealplan.planId
              ? r.row.original?.arealplan.planId
              : null}
          </Link>
        ),
        accessorFn: r => r.arealplan.planId,
        enableSorting: true,
        size: 70
      },
      {
        id: "behandlingBeskrivelse",
        accessorKey: "beskrivelse",
        header: () => "Beskrivelse",
        enableSorting: true,
        size: 200
      },
      {
        id: "fraDato",
        cell: r => (
          <>
            {r.row.original?.fraDato
              ? format(new Date(r.row.original.fraDato), "dd.MM.yyyy")
              : ""}
          </>
        ),
        accessorFn: (r, i) => r.fraDato && new Date(r.fraDato),
        header: () => "Fra dato",
        enableSorting: true,
        sortingFn: "datetime",
        size: 50
      },
      {
        id: "tilDato",
        cell: r => (
          <>
            {r.row.original?.tilDato
              ? format(new Date(r.row.original.tilDato), "dd.MM.yyyy")
              : ""}
          </>
        ),
        accessorFn: (r, i) => r.tilDato && new Date(r.tilDato),
        header: () => "Til dato",
        enableSorting: true,
        sortingFn: "datetime",
        size: 50
      },
      {
        id: "sak",
        accessorFn: (r, i) => {
          return r.sak ? `${r.sak?.sakAar}/${r.sak?.sakSeknr}` : null
        },
        header: () => "Saksnummer",
        enableResizing: true,
        enableSorting: true,
        size: 70
      },
      {
        id: "myndighet",
        accessorFn: (r, i) => r.myndighet,
        header: () => "Myndighet",
        enableSorting: true,
        size: 70
      },

      {
        id: "behandlingstype",
        accessorFn: (r, i) => r.behandlingstype,
        header: () => "Behandlingstype",
        enableSorting: true,
        size: 70
      },
      {
        id: "planNavn",
        accessorFn: (r, i) => r.arealplan.planNavn,
        header: () => "Plannavn",
        size: 100
      },
      {
        id: "planStatus",
        accessorFn: (r, i) => r.arealplan.planStatus,
        header: () => "Planstatus",
        enableSorting: true,
        size: 70
      },
      {
        id: "planType",
        accessorFn: (r, i) => r.arealplan.planType,
        header: () => "Plantype",
        enableSorting: true,
        size: 70
      }
    ],
    [kundeId]
  )
}
