
import * as React from 'react'
import { useMemo } from "react"
import { InfiniteData, useInfiniteQuery } from "react-query"
import { PlanforholdQueryVariables } from '../../features/reports/Reports/Planforhold/Planforhold'
import { GraphQLPagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"
import { ArealplanReport } from "./types"

const MapPagedArealplaner = (data: InfiniteData<GraphQLPagedResponse>) => {
  const mappedData = data?.pages.flatMap(b =>  {
    if(b.arealplanerPaged?.edges)
      return b.arealplanerPaged.edges.map(e => e.node)
    else return undefined
  }) as ArealplanReport[] 
  return mappedData 
} 

export default function useArealplanGraphQL(graphqlQuery: string, variables:PlanforholdQueryVariables) {
  
  const query = useInfiniteQuery<GraphQLPagedResponse>(
    ["arealplanReportGraphQL", variables],
    ({pageParam=variables}) => graphQLRequest(graphqlQuery, pageParam),
    {
        getNextPageParam: response => {
          if(response?.arealplanerPaged.pageInfo?.hasNextPage) {
            const test = {...variables, after: response.arealplanerPaged.pageInfo.endCursor}
            return test
          } else return undefined
          },
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)

    const arealplan: ArealplanReport[] = useMemo(() => {
      if(query.data) return MapPagedArealplaner(query.data) 
      else return [] as ArealplanReport[]
      }, [query.data?.pages])
    
      return { ...query,arealplan: arealplan|| [], totalCount: query.data ? query.data.pages[0]?.arealplanerPaged.totalCount : undefined}

  }