import { Arealplan } from "../../../hooks/arealplaner/types"

export const shouldShowExternalMapButton = (plan: Arealplan) => {
  return [4, 5, 9, 10].indexOf(plan.planStatusId) === -1
}

/** Har planen en av disse planstatusene:
 * Kun opprettet sak (foreløpig ikke annonsert),
 * Opphevet,
 * Utgått/erstattet,
 * Avvist,
 * Uaktuell/trukket
 */
export const shouldShowNoPlanMapInfoMessage = (plan: Arealplan) => {
  return !shouldShowExternalMapButton(plan) || plan.planStatusId === 0
}
