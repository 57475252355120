import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from "@mui/material"
import React from "react"
import { Form, FormRenderProps } from "react-final-form"

import FormSelectInput from "../../../components/FormSelectInput"
import LoadingSpinner from "../../../components/LoadingSpinner"
import SpinnerButton from "../../../components/SpinnerButton"
import {
  KodelisteTilgang,
  KodelisteType
} from "../../../hooks/arkivKodelister/types"
import { useArkivKodeliste } from "../../../hooks/arkivKodelister/useArkivKodeliste"
import { useArkivKodelisteTilganger } from "../../../hooks/arkivKodelister/useArkivKodelisteTilgang"
import useUpdateArkivKodelisteTilgang from "../../../hooks/arkivKodelister/useUpdateArkivKodelisteTilgang"
import useMeta from "../../../hooks/meta/useMeta"
import { useNotification } from "../../../hooks/useNotification"
import { orderBy } from "lodash-es"

type Props = {
  headerText: string
  arkivId: number
  kodelisteType: KodelisteType
}
type FormValues = {
  [id: string]: number
}

export const AccessRestrictionsForm = ({
  headerText,
  arkivId,
  kodelisteType,
  ...props
}: Props) => {
  const meta = useMeta()
  const kodeliste = useArkivKodeliste(arkivId, kodelisteType)
  const kodelisteTilganger = useArkivKodelisteTilganger(arkivId, kodelisteType)
  const updateKodelisteTilganger = useUpdateArkivKodelisteTilgang(
    arkivId,
    kodelisteType
  )
  const { notifySuccess, notifyError } = useNotification()

  const mapKodelisteTilgangerToFormValues = (kodeliste: KodelisteTilgang[]) => {
    const tilganger = kodeliste.map(kodelisteTilgang => {
      return [[`key${kodelisteTilgang.kodeverdi}`], kodelisteTilgang.tilgangId]
    })
    return Object.fromEntries(tilganger)
  }

  const mapFormValuesToKodelisteTilganger = (values: FormValues) => {
    return Object.entries(values).map(([key, value]) => {
      const cleanKey = key.substring(3)
      return {
        tilgangId: value,
        kodeverdi: cleanKey,
        kodebeskrivelse: kodeliste.data.find(e => e.id === cleanKey)
          ?.beskrivelse
      } as KodelisteTilgang
    })
  }

  const handleSubmit = async (values: FormValues) => {
    return updateKodelisteTilganger.mutateAsync(
      mapFormValuesToKodelisteTilganger(values),
      {
        onSuccess: notifySuccess("Innstillinger lagret"),
        onError: notifyError("Noe gikk galt under lagringen")
      }
    )
  }

  const renderForm = ({
    handleSubmit,
    pristine,
    submitting,
    submitErrors,
    values,
    form,
    errors
  }: FormRenderProps) => (
    <Box>
      <form
        id={`access-restriction-form-${kodelisteType}`}
        onSubmit={handleSubmit}
        autoComplete="off">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{headerText}</TableCell>
              <TableCell>Hvem kan se?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(kodeliste.data, "id")
              .filter(option => option.id !== "-1")
              .map((option, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": { backgroundColor: "#f0f0f0" }
                  }}>
                  <TableCell>{`${option.id} - ${option.beskrivelse}`}</TableCell>
                  <TableCell width={100}>
                    <FormSelectInput
                      name={`key${option.id}`}
                      options={meta.data.tilgangstyper}
                      keyCol="id"
                      valueCol="navn"
                      displayId={false}
                      nullOption={true}
                      nullOptionDisplayText="Ingen"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box marginTop={10}>
          <Button
            variant="contained"
            color="grey"
            onClick={() => form.reset()}
            disabled={submitting || pristine}>
            Avbryt
          </Button>

          <SpinnerButton
            type="submit"
            label="Lagre"
            style={{ marginLeft: "20px" }}
            disabled={submitting || pristine}
            loading={submitting}
          />
        </Box>
      </form>

      {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre>
      <pre>Pristine: {JSON.stringify(pristine, null, 2)}</pre>
      <pre>{JSON.stringify(submitting, null, 2)}</pre>
      <pre>{JSON.stringify(errors, null, 2)}</pre>
      <pre>{JSON.stringify(submitErrors, null, 2)}</pre> */}
    </Box>
  )

  if (!meta.isFetched) return null
  if (kodeliste.isLoading || kodelisteTilganger.isLoading)
    return <LoadingSpinner />
  return (
    <Form
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={mapKodelisteTilgangerToFormValues(kodelisteTilganger.data)}
    />
  )
}
